import { toast } from "react-toastify";

const validateAsset = function (ticker, assets, broker) {
	if (!ticker && !broker) {
		toast.error("Please select an asset and a broker");
		return false;
	}

	//Todo: Replace with regex pattern matching
	// if (
	// 	assets[broker].includes(ticker) ||
	// 	assets[broker].includes(ticker.substring(0, 1)) ||
	// 	assets[broker].includes(ticker.substring(0, 2)) ||
	// 	assets[broker].includes(ticker.substring(0, 3)) ||
	// 	assets[broker].includes(ticker.substring(0, 4)) ||
	// 	assets[broker].includes(ticker.substring(0, 5))
	// ) {
	// 	return ticker;
	// }

	return ticker;
};

export default validateAsset;

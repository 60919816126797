import React, { Fragment, useState } from "react";
import { useEffect, useGlobal } from "reactn";
import Modal from "components/common/modal";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import Axios from "axios";
import OptionTable from "./option-table";

const Assets = function (props) {
	const [assets] = useGlobal("assets");
	const [selectedBroker] = useGlobal("selectedBroker");
	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");
	const [selectedAssetClass, setSelectedAssetClass] = useGlobal(
		"selectedAssetClass"
	);
	const [selectedAssetQuote, setSelectedAssetQuote] = useGlobal(
		"selectedAssetQuote"
	);
	const [showContent, setShowContent] = useState(false);
	const [fetchingAssets] = useGlobal("fetchingAssets");
	const [showModal, setShowModal] = useState(false);
	const [optionChain, setOptionChain] = useState({});
	const [optionDates, setOptionDates] = useState([]);
	const [selectedOption, setSelectedOption] = useGlobal("selectedOption");

	const handleAssetInput = function (e) {
		setShowContent(true);
		setSelectedAsset(e.target.value.toUpperCase());
	};

	const handleSelectedAsset = function (e) {
		let ticker = e.target.dataset.ticker;
		setSelectedAsset(e.target.dataset.ticker);
		if (ticker !== selectedOption.root_symbol) {
			setSelectedAssetClass("equity");
		}
		if (selectedAssetQuote.type === "option") {
			setSelectedOption(selectedAsset);
		}
		setShowContent(false);
		//TODO: check if asset has option and display option chain
		//TODO: check if the selected asset is a valid asset with the broker
		//TODO: Display buying power for this asset
	};

	React.useLayoutEffect(() => {
		const timer = setTimeout(() => {
			function getQuote() {
				Axios.get(`${process.env.REACT_APP_BACKEND}getquote/${selectedAsset}`).then(
					(response) => {
						setSelectedAssetQuote(response.data);
						//console.log(response.data);

						//Send to chrome extension for updating on tradingview and other sites

						const exchangeCodes = {
							Q: "NASDAQ",
							N: "NYSE",
							Z: "BATS",
						};

						const sym =
							selectedAssetClass !== "option"
								? response.data.symbol
								: response.data.root_symbol;

						const symbol = exchangeCodes[response.data.exch] + ":" + sym;
						// chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
						// 	symbol: symbol,
						// });
						window.localStorage.setItem("symbol", symbol);
					}
				);
			}
			getQuote();
		}, 500);
		return () => clearTimeout(timer);
	}, [selectedAsset]);

	const closeContent = function (e) {
		setShowContent(false);
	};

	let tickers = assets[selectedBroker];
	const optionBrokers = ["robinhood", "tradier", "tradierPaper"];

	const handleOptionChain = function (e) {
		e.preventDefault();
		if (!selectedAsset) {
			toast.error("You must select an asset before loading the option chain");
			return false;
		} else {
			Axios.get(process.env.REACT_APP_BACKEND + "optionsexp/" + selectedAsset)
				.then((response) => {
					let optionDates = Object.keys(response.data);
					setOptionDates(optionDates);
					setOptionChain(response.data);
					console.log(optionDates);
				})
				.catch((errata) => console.error(errata.error));
		}
		setShowModal(true);
	};

	const handleOption = function (option, e) {
		//e.preventDefault();
		setSelectedOption(option);
		// setSelectedAsset(position.asset);
		// setSelectedBroker(position.broker);
	};

	const setOptionAsAsset = (e) => {
		e.preventDefault();

		setSelectedAsset(selectedOption.symbol);
		setSelectedAssetClass("option");
		setShowModal(false);
	};

	return (
		<Fragment>
			<div className="auto-complete relative">
				<input
					type="text"
					value={selectedAsset}
					onChange={handleAssetInput}
					onFocus={(e) => e.target.select()}
					className={props.styling}
					placeholder="Type Asset..."
				/>
				{selectedAssetClass !== "option" &&
					optionBrokers.includes(selectedBroker) &&
					props.chain === "normal" && (
						<span
							onClick={handleOptionChain}
							className="text-xs text-blue-700 underline flex justify-end cursor-pointer"
						>
							Option Chain
						</span>
					)}
				{selectedAssetClass === "option" && selectedOption && (
					<span className="text-xs text-blue-700 flex justify-end font-semibold -ml-20">
						{selectedOption.description}
					</span>
				)}

				{selectedAssetQuote && (
					<div className="text-xs -ml-20 flex justify-end ">
						Last: {selectedAssetQuote.last}, Bid: {selectedAssetQuote.bid}(
						{selectedAssetQuote.bidsize}), Ask:
						{selectedAssetQuote.ask}({selectedAssetQuote.asksize})
					</div>
				)}
				{showContent && (
					<div className="auto-complete-content cursor-pointer absolute -mt-1 text-xs bg-gray-500 w-20">
						{Array.isArray(tickers) && selectedAsset !== ""
							? tickers
									.filter((ticker) => {
										return ticker.toUpperCase().includes(selectedAsset.toUpperCase());
									})
									.sort()
									.reverse()
									.map((ticker, key) => {
										return (
											<span
												key={key + 1}
												className="block p-2 hover:bg-gray-400 w-full border-gray-600 first:border-t-0 border-t cursor-pointer"
												onClick={handleSelectedAsset}
												data-ticker={ticker}
											>
												{ticker.toUpperCase()}
											</span>
										);
									})
							: ""}
					</div>
				)}
			</div>
			<div>
				{showModal && (
					<Modal>
						<div className="flex justify-between p-2">
							<h4 className="font-semibold">
								Option Chain for {selectedAsset} - Last: {selectedAssetQuote.last}, Bid:{" "}
								{selectedAssetQuote.bid}, Ask: {selectedAssetQuote.ask}
							</h4>
							<button
								onClick={setOptionAsAsset}
								className="bg-yellow-400 hover:bg-yellow-600 px-3 border-radius-sm"
							>
								Commit
							</button>
						</div>

						<div className="overflow-x-auto text-xs m-1 p-2 ">
							{optionDates.map((optionDate) => {
								return (
									<React.Fragment>
										<OptionTable optionChain={optionChain} optionDate={optionDate} />
									</React.Fragment>
								);
							})}
						</div>
					</Modal>
				)}
			</div>
		</Fragment>
	);
};

export default Assets;

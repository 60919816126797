import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import Axios from "axios";
import ListRow from "./list-row";
import { toast } from "react-toastify";

const List = function () {
	const [reviewed, setReviewed] = useState();
	const [selectedList, setSelectedList] = useGlobal("selectedWatchlist");
	const [updateList, setUpdateList] = useGlobal("updateList");
	const [listSymbols, setListSymbols] = useState([]);

	useEffect(() => {
		if (!selectedList) return;
		Axios.get(
			process.env.REACT_APP_BACKEND +
				"watchlists_symbols?watchlistId=" +
				selectedList.id
		)
			.then((response) => {
				setListSymbols(response.data);
			})
			.catch((error) => {
				toast.error("There was a problem fetching the watchlist");
				console.log("Error Fetching Watchlist", error);
				setListSymbols([]);
			});
	}, [selectedList, updateList]);

	return (
		<>
			<div className="pt-2">
				{!selectedList && (
					<p className="text-xs italic">
						Select a watchlist from one of the above options to view it or add a
						symbol
					</p>
				)}
				{selectedList && (
					<>
						<div className="flex justify-between text-xs">
							<div>&laquo; Prev</div>
							<div className="font-semibold uppercase underline">
								{selectedList.name}
							</div>
							<div>Next &raquo;</div>
						</div>
						<div id="watchlist" className="mt-2">
							<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
								<div className="table min-w-full">
									<div className="table-header-group">
										<div className="table-row">
											<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
												Symbol
											</div>
											<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
												Price($)
											</div>
											<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
												Reviewed
											</div>
											<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
												Actions
											</div>
										</div>
									</div>
									<div className="table-row-group bg-white"></div>
									{listSymbols.length < 1 && (
										<p className="text-xs m-2 italic">
											No symbols have been added to this list yet. Click the "Add Symbol"
											button to get started.
										</p>
									)}
									{listSymbols.map((listItem) => {
										return <ListRow key={"list-" + listItem.id} listItem={listItem} />;
									})}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default List;

import React from "react";
import { useEffect, useGlobal } from "reactn";
import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";
import Row from "./small-positions-row";
import SlidePanel from "../../common/slide-panel";
import { toast } from "react-toastify";
import { FaCaretDown } from "react-icons/fa";

dayjs.extend(customParseFormat);

const SmallPositions = function (props) {
	const [positions, setPositions] = useGlobal("positions");
	const [selectedPosition, setSelectedPosition] = React.useState({});

	const [selctedAssetClass, setSelectedAssetClass] = useGlobal(
		"selectedAssetClass"
	);
	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");
	const [selectedBroker, setSelectedBroker] = useGlobal("selectedBroker");
	const [updateOrders, setUpdateOrders] = useGlobal("updateOrders");
	const [updatePositions, setUpdatePositions] = useGlobal("updatePositions");

	const [status, setStatus] = useGlobal("status");
	const [socketState, setSocketState] = React.useState({
		polygon: "",
		alpacaPaper: "",
	});

	let positionAssets = positions
		.map((position) => {
			let occPattern = /(\w{1,6})(\d{6})([cp])(\d{8})/gi;
			let matches = occPattern.exec(position.asset);
			let match = matches && matches[1] ? matches[1] : undefined;
			return match || position.asset;
		})
		.filter((value, index, self) => {
			return self.indexOf(value) === index;
		});

	let subscribeList = positionAssets.map((asset) => {
		return "AM." + asset;
	});

	const handlePosition = function (position, e) {
		//e.preventDefault();
		setSelectedPosition(position);
		setSelectedAsset(position.asset);
		setSelectedBroker(position.broker);
		if (position.asset.length > 6) {
			setSelectedAssetClass("option");
		} else {
			setSelectedAssetClass("equity");
		}
	};

	return (
		<React.Fragment>
			<div className={"overflow-x-auto text-xs m-1 p-2 " + props.className}>
				{/* <div id="filters" className="flex justify-between mb-4">
					<div className="inline-flex items-center">
						<button className="transition duration-500 bg-blue-700 hover:bg-blue-500 text-white cursor-pointer px-2 inline-flex text-xs leading-5 font-semibold">
							Open
						</button>
						<span className="w-full px-1 items-center h-full bg-yellow-100 cursor-pointer hover:bg-blue-200 text-blue-800 inline-flex text-xs leading-5 font-semibold">
							<FaCaretDown className=" " />
						</span>
					</div>

					<span className="bg-gray-100 cursor-pointer hover:bg-blue-200 text-blue-800 px-2 inline-flex text-xs leading-5 font-semibold">
						Closed
					</span>
					<span className="bg-gray-100 cursor-pointer hover:bg-blue-200 text-blue-800 px-2 inline-flex text-xs leading-5 font-semibold">
						Pending
					</span>
					<span className="bg-gray-100 cursor-pointer hover:bg-blue-200 text-blue-800 px-2 inline-flex text-xs leading-5 font-semibold">
						Watchlist
					</span>
				</div> */}

				{/* <div id="filterChild" className="flex flex-wrap mb-2 bg-yellow-100 p-2">
					<span className="mb-1 mr-1 bg-green-100 text-green-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
						All
					</span>
					<span className="mb-1 mr-1 bg-green-100 text-green-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
						Stonks
					</span>
					<span className="mb-1 mr-1 bg-green-100 text-green-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
						Options
					</span>
					<span className="mb-1 mr-1 bg-green-100 text-green-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
						Crypto
					</span>
					<span className="mb-1 mr-1 bg-green-100 text-green-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
						Day Trading
					</span>
					<span className="mb-1 mr-1 bg-green-100 text-green-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
						Swing Trading
					</span>
					<span className="mb-1 mr-1 bg-green-100 text-green-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
						Long Term
					</span>
					<span className="mb-1 mr-1 bg-green-100 text-green-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
						For Fun
					</span>
				</div> */}

				<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
					<div className="table min-w-full">
						<div className="table-header-group">
							<div className="table-row">
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Asset
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Entry($)
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Now($)
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									P/L
								</div>
							</div>
						</div>
						<div className="table-row-group bg-white">
							<React.Fragment>
								{positions.map((position) => {
									if (position.asset.length > 6 && position.broker == "tradierPaper") {
										let occPattern = /(\w{1,6})(\d{6})([cp])(\d{8})/gi;
										let matches = occPattern.exec(position.asset);

										position.optionUnderlying = matches[1];
										position.optionExpiry = dayjs(matches[2], "YYMMDD").format(
											"MMM D YYYY"
										);
										// let optionType = matches[3] === "C" ? "Call" : "Put";
										// position.optionType =
										// 	position.size > 1 ? optionType + "s" : optionType;
										position.optionType = matches[3];
										position.optionStrike = matches[4] / 1000;
									}

                                    if (position.asset.length > 6 && position.broker=="okexOptions") {
                                        const split = position.asset.split("-")
                                        position.optionUnderlying = split[0]
                                        position.optionExpiry = dayjs(split[2], "YYMMDD").format("MMM D YYYY")
                                        position.optionType = split[4]
                                        position.optionStrike = split[3]
                                    }

									return (
										<Row
											key={position.id}
											position={position}
											selectedPosition={selectedPosition}
											handlePosition={handlePosition}
										/>
									);
								})}
							</React.Fragment>
						</div>
					</div>
				</div>
			</div>
			{/* <SlidePanel type="right" size={50} noBackdrop>
				<p>This is the stuff that is going into the slider panel</p>
			</SlidePanel> */}
		</React.Fragment>
	);
};

export default SmallPositions;

import React from "react";
import { useGlobal } from "reactn";
import Assets from "../../common/assets";
import Brokers from "../../common/brokers";
import PromptBox from "../../layout/prompt-box";

const OrderTicket = function (props) {

    //const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");


    const [orderData, setOrderData] = React.useState({
        
    });

    const handleOrderData = function(e) {
        orderData[e.target.name] = e.target.value
        setOrderData(orderData)
        console.log(orderData)
    }

 

	return (
		<PromptBox title="Quick Order" {...props}>
			<div >
				<form>
					<div className="flex justify-between px-2">
						<div className="w-1/4 mr-1">
							<div className="flex-col relative">
                            {/*<label className="text-xs mr-1 uppercase text-gray-500">Asset</label>
								<input
                                    onChange={handleOrderData}
                                    value ={orderData.asset}
                                    name="asset"
									type="text"
									className="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white "
								/> */}
                                <Assets styling="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white " />
                                  
							</div>
						</div>
						<div className="w-1/4 mr-1">
                            <div>
                            {/*<label className="text-xs mr-1 uppercase text-gray-500">Broker</label>
						<input
                            onChange={handleOrderData}
                            value={orderData.broker}
                            name="broker"
							type="text"
							className="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white "
                            />*/}
                            <Brokers styling="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white " />
                            </div>
                        
                        </div>
						
						<div className="w-1/4 mr-1">
                            <div>
                            <label className="text-xs mr-1 uppercase text-gray-500">Size</label>
							<input
                                onChange={handleOrderData}
                                value={orderData.size}
                                name="size"
								type="text"
								className="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white "
							/>
                            </div>
							
						</div>
                        <div className="w-1/4">
                            <div>
                            <label className="text-xs mr-1 uppercase text-gray-500">Leverage</label>
							<input
                                onChange={handleOrderData}
                                value={orderData.leverage}
                                name="leverage"
								type="text"
								className="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white "
							/>
                            </div>
							
						</div>
					</div>
                    <div className="flex justify-between px-2">
						<div className="w-1/3 mr-1">
							<div className="flex-col relative">
								<label className="text-xs mr-1 uppercase text-gray-500">Duration</label>
								<input
                                    onChange={handleOrderData}
                                    value={orderData.duration}
                                    name="duration"
									type="text"
									className="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white "
								/>
							</div>
						</div>
						<div className="w-1/3 mr-1">
                            <div>
                            <label className="text-xs mr-1 uppercase text-gray-500">Type</label>
						<input
                            onChange={handleOrderData}
                            value={orderData.type}
                            name="type"
							type="text"
							className="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white "
						/>
                            </div>
                        
                        </div>
						
						<div className="w-1/3">
                            <div>
                            <label className="text-xs mr-1 uppercase text-gray-500">Rule</label>
							<input
                            onChange={handleOrderData}
                            value={orderData.rule}
                            name="rule"
								type="text"
								className="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white "
							/>
                            </div>
							
						</div>
					</div>
                    <div className="flex justify-between mt-1 text-gray-500">
                        <div className="w-1/3 text-center">0.0691</div>
                        <div className="w-1/3 text-center">0.0008 (2%)</div>
                        <div className="w-1/3 text-center">0.0698</div>
                    </div>
                    <div className="flex justify-between">
                        <div className=" w-1/3">
                        <button
                type="submit"
                className="focus:outline-none bg-red-700 p-2 w-full antialiased border border-red-700 tracking-widest hover:shadow hover:bg-red-600 hover:border-red-600 text-xs font-semibold uppercase p-1 px-4"
              >
                Sell/Short
              </button>
                        </div>
                        <div className="w-1/3 px-1"> 

                    <input
                        onChange={handleOrderData}
                        value={orderData.price}
                        name="price"
                        type="text"
                        placeholder="Price"
                        className="text-center p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white "
                    />

                    
                </div>
                        <div className="w-1/3">
                        <button
                type="submit"
                className="focus:outline-none bg-green-700 p-2 w-full antialiased border border-green-700 tracking-widest hover:shadow hover:bg-green-600 hover:border-green-600 text-xs font-semibold uppercase p-1 px-4"
              >
                Buy/Long
              </button>
                        </div>
                    </div>
				</form>

			</div>
		</PromptBox>
	);
};

export default OrderTicket;

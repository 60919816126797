import React, {
	Fragment,
	useState,
	useEffect,
	useRef,
	useLayoutEffect,
} from "react";
import { useGlobal } from "reactn";
import { FaTimes, FaBars, FaChevronCircleDown } from "react-icons/fa";
import Draggable from "react-draggable";
import Axios from "axios";
import { toast } from "react-toastify";
let Mousetrap = require("mousetrap-record")(require("mousetrap"));

const TriggerOrder = function () {
	let message = "";
	//let triggerLabel = "";
	const [trigger, setTrigger] = useState("");
	const [title, setTitle] = useState("");
	const [triggerDetails, setTriggerDetails] = useState("");
	const [orderTemplates, setOrderTemplates] = useState([]);
	const [ordtemp, setOrdtemp] = useState(0);
	const { 1: setDisplayTemplate } = useState({});
	const [syncTactics, setSyncTactics] = useGlobal("syncingTactics");
	const [syncOrderTemplates] = useGlobal("syncingOrderTemplates");
	const detailsRef = useRef();

	const handleOrdtemp = function (e) {
		let id = e.target.value;
		Axios.get(process.env.REACT_APP_BACKEND + "order-templates/" + id).then(
			(response) => {
				setDisplayTemplate(response.data);
				setOrdtemp(id);
			}
		);
	};

	const handleTrigger = function (e) {
		setTrigger(e.target.value);
	};

	const handleTriggerDetails = function (e) {
		setTriggerDetails(e.target.value);
	};

	useLayoutEffect(() => {
		detailsRef.current && detailsRef.current.focus();
		setTriggerDetails("");
	}, [trigger]);

	const hotkeyFocus = function () {
		if (trigger === "hotkey") {
			Mousetrap.record(function (sequence) {
				let keys = sequence.join(" ");
				setTriggerDetails(keys);
			}, 250);
		}
	};

	useEffect(() => {
		Axios.get(process.env.REACT_APP_BACKEND + "order-templates")
			.then((response) => setOrderTemplates(response.data))
			.catch((errata) => console.log(errata));
	}, [syncOrderTemplates]);

	const handleSubmit = function (e) {
		e.preventDefault();

		let formData = {
			type: trigger,
			ordtemp: ordtemp,
			title: title,
			payload: triggerDetails,
		};

		console.log(formData);

		Axios.post(`${process.env.REACT_APP_BACKEND}tactics`, formData)
			.then((response) => {
				console.log(response);
				toast.success("Tactic has been created successfully");
				message = "Tactic created";
				setSyncTactics(syncTactics + 1);
			})
			.catch((errata) => console.log(errata));
	};

	return (
		<Draggable handle=".draghandle">
			<div className="order-template bg-gray-700 text-xs">
				<div className="flex justify-between">
					<span
						title="Drag Panel"
						className="draghandle text-gray-400 cursor-move p-1 bg-gray-800"
					>
						<FaBars />
					</span>
					<span className="mx-2 text-xs uppercase text-gray-400 pt-1 font-bold">
						Tactical Actions
					</span>
					<span className="text-red-600 cursor-pointer p-1 bg-gray-800">
						<FaTimes />
					</span>
				</div>
				<div className="flex flex-col m-2 p-1 text-gray-200 justify-start">
					<div className="flex w-full">
						<div className="mr-1 flex-col mb-2 flex items-baseline w-1/3">
							<label
								htmlFor="triggers"
								className="text-xs mr-1 uppercase text-gray-500"
							>
								Trigger
							</label>
							<div className="relative w-full">
								<select
									name="triggers"
									value={trigger}
									onChange={handleTrigger}
									className="block w-full appearance-none rounded-none pr-8 bg-gray-800 p-1 focus:outline-none"
								>
									<option value=""></option>
									<option value="callback">Webhook URL</option>
									<option value="time">Time</option>
									<option value="button">Button</option>
									<option value="hotkey">Hotkey</option>
									<option value="price">Price</option>
									<option value="indicator">Indicator</option>
									<option value="bp">Buying Power</option>
									<option value="pnl">Position pnl</option>
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-200">
									<FaChevronCircleDown />
								</div>
							</div>
						</div>
						<div className="flex-col mb-2 flex items-baseline w-2/3">
							{trigger === "callback" && (
								<Fragment>
									<label
										htmlFor="trigger-details"
										className="text-xs mr-1 uppercase text-gray-500"
									>
										Custom URL
									</label>
									<input
										type="text"
										name="trigger-details"
										ref={detailsRef}
										onChange={handleTriggerDetails}
										className="block w-full appearance-none rounded-none pr-8 bg-gray-800 p-1 focus:outline-none"
									/>
								</Fragment>
							)}
							{trigger === "time" && (
								<Fragment>
									<label
										htmlFor="trigger-details"
										className="text-xs mr-1 uppercase text-gray-500"
									>
										Date and Time
									</label>
									<input
										type="datetime-local"
										id="trigger-details"
										name="trigger-details"
										ref={detailsRef}
										onChange={handleTriggerDetails}
										value={triggerDetails}
										className="block w-full appearance-none rounded-none pr-8 bg-gray-800 p-1 focus:outline-none"
									/>
								</Fragment>
							)}
							{trigger === "button" && (
								<Fragment>
									<label
										htmlFor="trigger-details"
										className="text-xs mr-1 uppercase text-gray-500"
									>
										Button Name
									</label>
									<input
										type="text"
										id="trigger-details"
										name="trigger-details"
										ref={detailsRef}
										onChange={handleTriggerDetails}
										value={triggerDetails}
										className="block w-full appearance-none rounded-none pr-8 bg-gray-800 p-1 focus:outline-none"
									/>
								</Fragment>
							)}
							{trigger === "hotkey" && (
								<Fragment>
									<label
										htmlFor="trigger-details"
										className="text-xs mr-1 uppercase text-gray-500"
									>
										Keyboard Binding
									</label>
									<input
										type="text"
										ref={detailsRef}
										name="trigger-details"
										onChange={handleTriggerDetails}
										value={triggerDetails}
										onFocus={hotkeyFocus}
										className="block w-full appearance-none rounded-none pr-8 bg-gray-800 p-1 focus:outline-none"
									/>
								</Fragment>
							)}
						</div>
					</div>

					<div className="mr-1 flex flex-col items-baseline mb-2">
						<label className="mr-1 uppercase text-gray-500">Order Template</label>
						<div className="relative w-full">
							<select
								value={ordtemp}
								onChange={handleOrdtemp}
								name="order-templates"
								className="block w-full appearance-none rounded-none bg-gray-800 p-1 focus:outline-none"
							>
								<option></option>
								{orderTemplates.map((value) => {
									return (
										<option value={value.id} key={value.id}>
											{value.title}
										</option>
									);
								})}
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-200">
								<FaChevronCircleDown />
							</div>
						</div>
					</div>
					<div className="mb-4 flex items-end">
						<div className="w-2/3">
							<input
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								type="text"
								placeholder="Tactic Title"
								className="block p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full text-white "
							/>
						</div>
						<div className="w-1/3 px-1">
							<button
								type="submit"
								onClick={handleSubmit}
								className="focus:outline-none bg-yellow-500 rounded-sm w-full antialiased border border-yellow-500 tracking-widest hover:shadow hover:bg-yellow-400 hover:border-yellow-400 text-xs font-semibold uppercase p-1 text-gray-900"
							>
								Create
							</button>
						</div>
					</div>
					<div className="flex flex-wrap bg-gray-800 border-dashed p-2 border border-gray-500 text-gray-600 ">
						<code className="w-48">{message}</code>
					</div>
				</div>
			</div>
		</Draggable>
	);
};

export default TriggerOrder;

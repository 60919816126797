import React from "react";
import { useGlobal } from "reactn";
import useContextMenu from "contextmenu";
import "contextmenu/ContextMenu.css";
import NumberFormat from "react-number-format";
import socket from "../../../services/socket";

import { useEffect, useMemo } from "react";

const SmallPositionsRow = function ({
	position,
	selectedPosition,
	handlePosition,
}) {
	const { 1: setBottomPanel } = useGlobal("bottomPanelControl");
	const [contextMenu, useCM] = useContextMenu({ subMenuSymbol: "O" });

	const [equityData, setEquityData] = React.useState({
		price: 0,
	});
	const [socketPrice, setSocketPrice] = React.useState();
	const [optionData, setOptionData] = React.useState();

	console.log(socket.connected);

	useEffect(() => {
		// side effects
		socket.on("30seconds", (data) => {
			console.log("data received");
			if (data.s == position.asset && data.c != socketPrice) {
				setSocketPrice(data.c);
			}
			// if (data.symbol == position.asset && data.price != socketPrice) {
			// 	setSocketPrice(data.price);
			// }
		});

		// cleanup
		return () => {
			socket.removeAllListeners("30seconds");
		};
	}, []);

	let price = socketPrice ? socketPrice : position.price;

	let pnl = socketPrice
		? (price - position.entry) * position.size
		: position.pnl;

	let underlyingPrice =
		optionData && optionData.symbol == position.asset
			? optionData.close
			: position.price;

	const menuConfig = function (data = {}) {
		return {
			"Add Note": addNote,
			"View Note": viewNote,
			"First Divider": <hr></hr>,
			"Tactics Header": <p className="font-semibold ml-1">Tactics</p>,
			"Reduce Half": handleTactic,
			"Close Position": () => handleTactic("close"),
			"Second Divider": <hr></hr>,
			"View Rules": {
				"Rule 1 ": () => handleRule("rule1"),
				"Rule 2": () => handleRule("rule2"),
			},
		};
	};

	const setContextMenu = useCM;

	const addNote = function (e, data) {
		console.log("Clicked on add note");
		handlePosition(position, e);
	};

	const viewNote = function (e, data) {};

	const handleTactic = function (tacticName) {
		return tacticName;
	};

	const handleNotesPanel = function (e) {
		e.preventDefault();
		setBottomPanel(true);
	};

	const handleRule = function (e, data) {};
	return (
		<div
			className={
				(selectedPosition.id === position.id
					? "bg-yellow-200 hover:bg-yellow-200"
					: "hover:bg-yellow-100 active:bg-yellow-100") +
				" table-row cursor-pointer text-xs"
			}
			onContextMenu={setContextMenu(menuConfig)}
			onClick={(e) => handlePosition(position, e)}
			onDoubleClick={handleNotesPanel}
		>
			<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>
					{position.optionUnderlying || position.asset}
					{position.optionType && (
						<span
							className={`${
								position.optionType === "C" ? "bg-blue-200" : "bg-purple-200"
							} inline-block rounded-sm`}
						>
							{position.optionType}
							<NumberFormat
								value={position.optionStrike}
								displayType="text"
								thousandSeparator
								decimalScale={2}
								prefix="$"
							/>
						</span>
					)}{" "}
					<span title="quantity" className="font-semibold">
						*{position.size}
					</span>
				</div>
				<div
					className={`${
						position.optionType === "C" ? "text-blue-500" : "text-purple-500"
					}`}
				>
					{position.optionExpiry}
				</div>
				<div className=" text-gray-600">{position.brokerUI}</div>
			</div>
			<div className="table-cell pl-1 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>
					<NumberFormat
						value={position.entry}
						displayType="text"
						thousandSeparator
						decimalScale={2}
					/>
				</div>
				{!position.optionType && (
					<div title="total entry value" className="text-gray-600">
						<NumberFormat
							value={position.entry * position.size}
							displayType="text"
							thousandSeparator
							decimalScale={2}
						/>
					</div>
				)}
				{position.optionType && (
					<div title="total entry value" className="text-gray-600">
						<NumberFormat
							value={position.entry * position.size * 100}
							displayType="text"
							thousandSeparator
							decimalScale={2}
						/>
					</div>
				)}
			</div>
			<div className="table-cell pl-1 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>
					<NumberFormat
						value={price}
						displayType="text"
						thousandSeparator
						decimalScale={2}
					/>
				</div>
				{!position.optionType && (
					<div title="total current value" className="text-gray-600">
						<NumberFormat
							value={price * position.size}
							displayType="text"
							thousandSeparator
							decimalScale={2}
						/>
					</div>
				)}
				{position.optionType && (
					<div title="total entry value" className="text-gray-600">
						<NumberFormat
							value={price * position.size * 100}
							displayType="text"
							thousandSeparator
							decimalScale={2}
						/>
					</div>
				)}
			</div>
			<div className="table-cell pl-1 pr-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>
					<NumberFormat
						value={((price - position.entry) * 100) / position.entry}
						displayType="text"
						thousandSeparator
						decimalScale={2}
					/>
					%
				</div>
				<div
					title="Total PnL"
					className={
						(pnl > 0 ? "text-green-600" : "text-red-600") + " font-semibold"
					}
				>
					<NumberFormat
						value={pnl}
						displayType="text"
						thousandSeparator
						decimalScale={2}
					/>
				</div>
			</div>
			{contextMenu}
		</div>
	);
};

const Row = function (props) {
	return <SmallPositionsRow {...props} />;
};

export default Row;

import React, { useState, useEffect } from "react";
import PositionTactics from "./position-tactics";
import Tactic from "./tactic";
import uid from "uid";
import { FaPlus } from "react-icons/fa";
//import Filter from "./tactics/filter";
import { useGlobal } from "reactn";
//import FilterResults from "react-filter-search";
import OrderTemplate from "./order-template";
import TriggerOrder from "./trigger-order";
import Axios from "axios";
import { toast } from "react-toastify";
import PositionSelect from "./position-select";

function Tactics() {
	const [tactics, setTactics] = useGlobal("tactics");
	const [syncTactics, setSyncTactics] = useGlobal("syncingTactics");
	const [tacticsFilter, setTacticsFilter] = useState("");
	// const [newDropdown, setNewDropdown] = useState("hidden");
	// const toggleDropdown => setNewDropdown("")
	const handleTacticsFilter = function (e) {
		setTacticsFilter(e.target.value);
	};

	useEffect(() => {
		Axios.get(process.env.REACT_APP_BACKEND + "tactics")
			.then((response) => {
				//console.log(response.data);
				setTactics(response.data);
			})
			.catch((errata) => console.log(errata.error));
	}, [syncTactics]);

	return (
		<div className="flex">
			<div className="w-2/3 mr-4">
				<h2 className="mb-4 text-2xl">Tactics Library</h2>
				<div>
					Tactics are groups of actions, triggers, and orders used to
					manage a market position
					<div className="block w-full">
						<input
							type="text"
							className="form-input mt-3 block w-3/4"
							placeholder="Filter Tactics..."
							value={tacticsFilter}
							onChange={handleTacticsFilter}
						/>
					</div>
				</div>
				<div>
					<div className="flex mt-4 pr-4 text-xs font-semibold justify-between">
						<div className="dropdown">
							<button className="bg-teal-500 inline-flex items-center rounded-sm antialiased border border-teal-500 tracking-widest hover:shadow hover:bg-teal-400 hover:border-teal-400 text-xs font-bold uppercase p-1 px-4 ">
								<FaPlus className="h-auto" />
								<span className="inline-block pl-2 h-auto border-gray-700">
									New
								</span>
							</button>
							<div className="dropdown-content hidden cursor-pointer absolute mt-1 bg-teal-500">
								<span className="block p-2 hover:bg-teal-400 w-full">
									Tactical Action
								</span>
								<span className="block p-2 hover:bg-teal-400 w-full">
									Trigger Template
								</span>
								<span className="block p-2 hover:bg-teal-400 w-full">
									Order Template
								</span>
							</div>
						</div>
						<div className="flex mr-4">
							<span className="block px-4 py-2 mx-2 bg-gray-200 cursor-pointer hover:bg-gray-400">
								Tactical Actions
							</span>
							<span className="block px-4 py-2 mr-2 bg-gray-300 hover:bg-gray-400 cursor-pointer">
								Trigger Templates
							</span>
							<span className="block px-4 py-2 bg-gray-300 hover:bg-gray-400 cursor-pointer">
								Order Templates
							</span>
						</div>
					</div>
					<div className="p-4 pl-8 bg-gray-200">
						<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 sm:gap-2 md:gap-4">
							{tactics
								.filter((tactic) => {
									return (
										tactic["title"]
											.toLowerCase()
											.includes(
												tacticsFilter.toLowerCase()
											) ||
										tactic.tactic_ordtemps[0].title
											.toLowerCase()
											.includes(
												tacticsFilter.toLowerCase()
											)
									);
								})
								.map((tactic, index) => (
									<Tactic
										key={index}
										tid={tactic.id}
										action={
											tactic.tactic_ordtemps[0].action
										}
										title={tactic.title}
										intent={
											tactic.tactic_ordtemps[0].intent
										}
										quantity={
											tactic.tactic_ordtemps[0].size
										}
										ordTempTitle={
											tactic.tactic_ordtemps[0].title
										}
										trigger={tactic.trigtemp}
									/>
								))}
						</div>
					</div>
				</div>
				<p className="mt-5">
					<button className="bg-yellow-500 rounded-sm antialiased border border-yellow-500 tracking-widest hover:shadow hover:bg-yellow-400 hover:border-yellow-400 text-xs font-semibold uppercase p-1 px-4 ml-3">
						Create New Tactic
					</button>
				</p>
				<div className="absolute flex justify-between">
					<OrderTemplate />
					<div className="mr-4"></div>
					<TriggerOrder />
				</div>

				<div className="mt-5 text-gray-700 w-1/2 m-auto bg-gray-300 border-gray-500">
					<div className="p-5 flex justify-between">
						<button className="rounded-sm antialiased border border-yellow-500 tracking-widest hover:shadow hover:border-yellow-400 text-xs font-semibold uppercase p-1 px-4 ml-3">
							+ Add Another Action
						</button>
						<button className="bg-yellow-500 rounded-sm antialiased border border-yellow-500 tracking-widest hover:shadow hover:bg-yellow-400 hover:border-yellow-400 text-xs font-semibold uppercase p-1 px-4 ml-3">
							Submit
						</button>
					</div>
				</div>
			</div>
			<div className="w-1/3 bg-gray-300 h-48 h-auto p-4">
				<span className="text-xs">
					Select an existing position or create a new position, and
					click the plus sign on the tactics you want to add
				</span>
        <PositionSelect />
				<PositionTactics />
			</div>
		</div>
	);
}

export default Tactics;

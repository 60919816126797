import React from "react";
import SmallPositions from "./small-positions";

const Positions = function (props) {
	return (
		<React.Fragment>
			{props.size === "small" && <SmallPositions {...props} />}
		</React.Fragment>
	);
};

export default Positions;

import React, { Fragment, useState } from "react";
import { useGlobal } from "reactn";
import { FaTimes, FaBars } from "react-icons/fa";
import Draggable from "react-draggable";
import Axios from "axios";
import { toast } from "react-toastify";

const OrderTemplate = function () {
  const assets = ["SPY", "AAPL", "MU"];

  const [action, setAction] = useState("entry");
  const [intent, setIntent] = useState("");
  const [type, setType] = useState("");
  //const [disablePrice, setDisablePrice] = useState(false);
  const [duration, setDuration] = useState("");
  const [asset, setAsset] = useState("any");
  const [price, setPrice] = useState(0.0);
  const [size, setSize] = useState(0);

  const [title, setTitle] = useState("");

  const [syncOrderTemplates, setSyncOrderTemplates] = useGlobal(
    "syncingOrderTemplates"
  );

  let disablePrice = false;
  if (type === "market") disablePrice = true;
  const handleType = function (e) {
    setType(e.target.value);
    if (e.target.value === "market-limit" || e.target.value === "market")
      setPrice(0);
  };

  const handleAction = function (e) {
    setAction(e.target.value);
  };

  const formData = {
    action: action,
    intent: intent,
    type: type,
    duration: duration,
    asset: asset,
    price: price,
    size: size,
    title: title,
  };

  const handleForm = function (e) {
    e.preventDefault();
    console.log("create button clicked");
    const intentArr = ["long", "short", "reduce", "close"];
    const typesArr = ["market", "limit", "market-limit", "stop"];
    const durationArr = ["gtc", "day", "ioc", "fok", "opg"];
    if (!intentArr.includes(intent)) return false;
    if ((price === "limit" || price === "stop") && price <= 0) return false;
    Axios.post("http://localhost:3003/order-templates/new", formData)
      .then((response) => {
        console.log(response);
        toast.success(
          "Order template created successfully - " + formData.title
        );
        //make order template drop-downs re-render
        setSyncOrderTemplates(syncOrderTemplates + 1);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Draggable handle=".draghandle">
      <div className="order-template bg-gray-700 text-xs">
        <div className="flex justify-between">
          <span
            title="Drag Panel"
            className="draghandle text-gray-400 cursor-move p-1 bg-gray-800"
          >
            <FaBars />
          </span>
          <span className="mx-2 text-xs uppercase text-gray-400 pt-1 font-bold">
            Order Template
          </span>
          <span className="text-red-600 cursor-pointer p-1 bg-gray-800">
            <FaTimes />
          </span>
        </div>
        <div className="flex justify-between uppercase text-gray-300 cursor-pointer mt-2 ">
          <button
            className={
              (action === "entry" ? "" : " hover:bg-blue-600 ") +
              "p-2 bg-blue-700 w-1/2 focus:outline-none hover:bg-blue-600 font-bold"
            }
            value="entry"
            onClick={handleAction}
          >
            Entry
          </button>
          <button
            className={
              (action === "exit" ? "" : " hover:bg-purple-600 ") +
              "p-2 bg-purple-700 w-1/2 focus:outline-none"
            }
            value="exit"
            onClick={handleAction}
          >
            Exit
          </button>
        </div>
        <form
          className={
            (action === "entry" ? "border-blue-700" : "border-purple-700") +
            " border-2 p-1"
          }
          onSubmit={handleForm}
        >
          <div className="flex justify-between text-gray-200 p-1">
            <div className="w-1/3 mr-1">
              <div className="relative w-full">
                <select
                  className="w-full appearance-none rounded-none bg-gray-800 p-1 focus:outline-none"
                  value={intent}
                  onChange={(e) => setIntent(e.target.value)}
                >
                  <option value=""> - Intent - </option>
                  {action === "entry" ? (
                    <Fragment>
                      <option value="long">Long</option>
                      <option value="short">Short</option>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <option value="reduce">Reduce</option>
                      <option value="close">Close</option>
                    </Fragment>
                  )}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-1/3 mr-1">
              <div className="relative w-full">
                <select
                  value={type}
                  onChange={handleType}
                  className="block w-full appearance-none rounded-none pr-8 bg-gray-800 p-1 focus:outline-none"
                >
                  <option value=""> - Type - </option>
                  <option value="limit">Limit</option>
                  <option value="market">Market</option>
                  <option value="market-limit">Market Limit</option>
                  <option value="stop">Stop</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-200">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-1/3">
              <div className="relative w-full">
                <select
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="block w-full appearance-none rounded-none pr-8 bg-gray-800 p-1 focus:outline-none"
                >
                  <option value="">- Duration -</option>
                  <option value="gtc">GTC</option>
                  <option value="day">Day</option>
                  <option value="fok">FOK</option>
                  <option value="opg">OPG</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-200">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between text-gray-200 p-1 pt-0">
            <div className="w-1/3 mr-1">
              <div className="relative w-full">
                <select
                  value={asset}
                  onChange={(e) => setAsset(e.target.value)}
                  className="block w-full appearance-none rounded-none pr-8 bg-gray-800 p-1 focus:outline-none"
                >
                  <option value="any">All Assets</option>
                  <option>AAPL</option>
                  <option>EVBG</option>
                  <option>GILD</option>
                  <option>BTC/USD</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 text-gray-200">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-1/3 mr-1">
              <div className="relative">
                <input
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  disabled={disablePrice ? "disabled" : ""}
                  className={
                    (disablePrice ? "cursor-not-allowed bg-white " : "") +
                    "p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full"
                  }
                  placeholder="Price"
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 text-gray-600">
                  <span className="">
                    {type === "market-limit" ? "Precision" : "Price"}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-1/3">
              <div className="relative">
                <input
                  type="text"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                  className="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full "
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 text-gray-600">
                  <span className="">
                    Size{action === "exit" ? "(%)" : "(#)"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex mt-1 p-1 justify-between">
            <div className="w-2/3">
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                placeholder="Template Name"
                className="p-1 appearance-none bg-gray-800 focus:outline-none focus:bg-gray-900 w-full h-full text-white "
              />
            </div>
            <div className="w-1/3 px-1">
              <button
                type="submit"
                className="focus:outline-none bg-yellow-500 rounded-sm w-full antialiased border border-yellow-500 tracking-widest hover:shadow hover:bg-yellow-400 hover:border-yellow-400 text-xs font-semibold uppercase p-1 px-4"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </Draggable>
  );
};

export default OrderTemplate;

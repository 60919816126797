import React from "react";
import { useGlobal } from "reactn";
import SlidingPanel from "react-sliding-side-panel";

const SlidePanel = function (props) {
	const { origin, children } = props;
	const [display, setDisplay] = useGlobal("bottomPanelControl");
	const closeDisplay = function (e) {
		e.preventDefault();
		setDisplay(false);
	};
	const [button, setButton] = React.useState();

	const handleClick = function (e) {
		setButton(!button);
	};

	/* 
    Common props you may want to specify include:

    type - left | right | top | bottom, specify the sliding panel position
    size - number between 0 and 100, specify the sliding panel size in percentage of the page size
    isOpen - boolean, open or close the sliding panel

This component uses CSSTransition under the hood, so you can also specify the following props:

    panelContainerClassName - an optional additional classname for the panel container
    panelClassName - an optional additional classname for the panel content
    noBackdrop - an optional boolean to set to true if you don't want a backdrop panel
    onOpen - Similar to onEnter
    onOpening - Similar to onEntering
    onOpened - Similar to onEntered
    onClose - Similar to onExit
    onClosing - Similar to onExiting
    onClosed - Similar to onExited
    backdropClicked - Callback called when the backdrop is clicked
    */

	return (
		<SlidingPanel
			type={"bottom"}
			isOpen={display}
			panelClassName={"bg-gray-500 z-0"}
			{...props}
		>
			<div>
				<div className="flex justify-end ">
					<button
						onClick={closeDisplay}
						className="m-1 cursor-pointer bg-gray-400 rounded p-1 px-2"
					>
						Close
					</button>
				</div>

				<div className="p-5">{children}</div>
			</div>
		</SlidingPanel>
	);
};

export default SlidePanel;

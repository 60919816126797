import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import Axios from "axios";
import numeral from "numeral";

const ClosedPositionsRow = function (props) {
	const { position } = props;
	const [selectedPosition, setSelectedPosition] = useState({
		id: 0,
	});

	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");

	const handleSymbol = function (position, e) {
		setSelectedPosition(position);
		setSelectedAsset(position.symbol);
	};

	const computeAvgExit = function (orders, position) {
		const filledTotals = orders
			.filter(
				(order) =>
					(position.direction == "long" && order.intent == "sell") ||
					(position.direction == "short" && order.intent == "buy")
			)
			.reduce(
				({ size, total }, order) => ({
					size: size + order.fillSize,
					total: total + order.fillSize * order.fillPrice,
				}),
				{ size: 0, total: 0 }
			);

		return {
			average: filledTotals.total / filledTotals.size,
			size: filledTotals.size,
		};
	};

	const exit = computeAvgExit(position.brokorders, position);

	return (
		<div
			className={
				(selectedPosition.id === position.id
					? "bg-yellow-200 hover:bg-yellow-200"
					: "hover:bg-yellow-100 active:bg-yellow-100") +
				" table-row cursor-pointer text-xs"
			}
			//onContextMenu={setContextMenu(menuConfig)}
			onClick={(e) => handleSymbol(position, e)}
			//onDoubleClick={handleNotesPanel}
		>
			<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>
					{position.asset} <span className="font-semibold">* {exit.size}</span>
				</div>
			</div>
			<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>{position.avgEntry}</div>
			</div>
			<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>{numeral(exit.average).format("0.00")}</div>
			</div>
			<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>
					{numeral((exit.average - position.avgEntry) / position.avgEntry).format(
						"0.00%"
					)}
				</div>
			</div>
		</div>
	);
};

export default ClosedPositionsRow;

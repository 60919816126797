import React from "react";
import { useEffect, useGlobal } from "reactn";
import nearestNumber from "nearest-number";
import { FaOptinMonster } from "react-icons/fa";
import isEmpty from "lodash/isEmpty";
import Axios from "axios";
import ScrollIntoView from "react-scroll-into-view";

const OptionTable = function (props) {
	const [selectedOption, setSelectedOption] = useGlobal("selectedOption");
	const [selectedAssetQuote, setSelectedAssetQuote] = useGlobal(
		"selectedAssetQuote"
	);
	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");
	const [chain, setChain] = React.useState([]);
	const scrollRef = React.useRef();

	const { optionChain, optionDate } = props;

	const loadOptionChain = function (date) {
		if (isEmpty(optionChain[optionDate])) {
			Axios.get(
				`${process.env.REACT_APP_BACKEND}optionchains/${selectedAsset}/${date}`
			).then((response) => {
				setChain(response.data);
			});
		} else {
			setChain(optionChain[optionDate]);
		}
	};

	// useEffect(() => {
	// 	const strikes = chain.map((option) => option.strike);
	// 	const nearestStrike = nearestNumber(strikes, selectedAssetQuote.last);
	// 	scrollRef.current &&
	// 		scrollRef.current.ScrollIntoView({
	// 			behavior: "smooth",
	// 		});
	// }, [chain]);

	// useEffect(() => {
	// 	const setChainIfExists = function () {
	// 		if (!isEmpty(optionChain[optionDate])) {
	// 			setChain(optionChain[optionDate]);
	// 		}
	// 	};
	// 	setChainIfExists();
	// 	return () => {
	// 		setChainIfExists();
	// 	};
	// }, [1]);

	const handleOption = function (option, e) {
		console.log(option.symbol);
		setSelectedOption(option);
	};

	return (
		<React.Fragment>
			<div
				className="bg-blue-100 p-2 font-semibold border-radius-t text-center cursor-pointer"
				onClick={(e) => loadOptionChain(optionDate)}
			>
				{optionDate}
			</div>

			<div className="align-middle inline-block flex min-w-full shadow overflow-y-scroll sm:rounded-lg border-b border-gray-200 h-64">
				<div className="w-1/2 ">
					<div className="table min-w-full">
						<div className="table-header-group">
							<div className="table-row">
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Type
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Bid
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Ask
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									OI
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Strike
								</div>
							</div>
						</div>
						<div className="table-row-group bg-white">
							<React.Fragment>
								{chain &&
									chain
										.filter((option) => option.option_type === "call")
										.map((option) => {
											return (
												<div
													className={
														(selectedOption.symbol === option.symbol
															? "bg-yellow-200 hover:bg-yellow-200"
															: "hover:bg-yellow-100") + " table-row cursor-pointer"
													}
													key={option.symbol}
													id={`option${option.strike}`}
													ref={scrollRef}
													onClick={(e) => handleOption(option, e)}
												>
													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.option_type}</div>
													</div>
													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.bid}</div>
													</div>
													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.ask}</div>
													</div>
													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.open_interest}</div>
													</div>
													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.strike}</div>
													</div>
												</div>
											);
										})}
							</React.Fragment>
						</div>
					</div>
				</div>
				<div className="w-1/2">
					<div className="table min-w-full">
						<div className="table-header-group">
							<div className="table-row">
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Strike
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									OI
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Bid
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Ask
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Type
								</div>
							</div>
						</div>

						<div className="table-row-group bg-white">
							<React.Fragment>
								{chain &&
									chain
										.filter((option) => option.option_type === "put")
										.map((option) => {
											return (
												<div
													className={
														(selectedOption.symbol === option.symbol
															? "bg-yellow-200 hover:bg-yellow-200"
															: "hover:bg-yellow-100") + " table-row cursor-pointer"
													}
													key={option.symbol}
													onClick={(e) => handleOption(option, e)}
												>
													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.strike}</div>
													</div>
													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.open_interest}</div>
													</div>
													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.bid}</div>
													</div>
													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.ask}</div>
													</div>

													<div className="table-cell px-2 py-2 whitespace-no-wrap border-b border-gray-200">
														<div>{option.option_type}</div>
													</div>
												</div>
											);
										})}
							</React.Fragment>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default OptionTable;

import React from "react";
import Categories from "./children/categories";
import List from "./children/list";
import Topbar from "./children/topbar";

const Watchlists = function (props) {
	return (
		<>
			<Topbar />
			<div className="border-t mt-2 py-2">
				<Categories />
				<List />
			</div>
		</>
	);
};

export default Watchlists;

import React, { Fragment, useState } from "react";
import { useEffect, useGlobal } from "reactn";
import Axios from "axios";

const Details = function (props) {
	const item = props.item;
	const { 0: updateTactics } = useGlobal("updateTactics");
	const { 0: updateOrders } = useGlobal("updateOrders");
	const [pendingTactics, setPendingTactics] = useState([]);
	const [pendingOrders, setPendingOrders] = useState([]);
	useEffect(() => {
		const fetchPendingTactics = async function () {
			Axios.get(
				`${process.env.REACT_APP_BACKEND}rules?asset=${item.asset}`
			)
				.then((response) => {
					setPendingTactics(response.data);
					//console.log(response.data);
				})
				.catch((errata) =>
					console.log(
						"Could not fetch pending tactics because " +
							errata.message
					)
				);
		};
		fetchPendingTactics();
	}, [updateTactics]);

	useEffect(() => {
		const fetchPendingOrders = async function () {
			Axios.get(
				process.env.REACT_APP_BACKEND +
					"orders?asset=" +
					item.asset +
					"&broker=" +
					item.broker +
					"&status=inactive"
			)
				.then((response) => {
					setPendingOrders(response.data);
					console.log(response.data);
				})
				.catch((errata) =>
					console.log(
						"could not fetch pending orders because " + errata
					)
				);
		};
		fetchPendingOrders();
	}, [updateOrders]);
	return (
		<Fragment>
			<div className="p-2 pl-10 bg-gray-800 text-white">
				<div>
					We are going to display the details for positions and orders
					here
				</div>
				<div className="flex">
					<div className="w-1/3">
						<h2>{item.asset}</h2>
						<p>Broker: {item.broker}</p>
						<p>P&nbsp;L: {item.pnl}</p>
						<p>Entry Price: {item.entry}</p>
						<p>Current Price: {item.price}</p>
						<p>Quantity: {item.size}</p>
						<p>Direction: {item.direction}</p>
						<p>Cost: {item.cost}</p>
						<p>Value: {item.value}</p>
					</div>
					<div className="w-1/3">
						<div>
							<h4>Apply Tactics to this position</h4>
							<div className="block w-full">
								<input
									type="text"
									className="form-input mt-3 block w-3/4"
									placeholder="Filter Tactics..."
								/>
							</div>
						</div>
						<div className="mt-2">
							<h4>Pending Tactics for this position</h4>
							{pendingTactics.length > 0 && (
								<Fragment>
									<ol className="list-decimal list-inside text-xs">
										{pendingTactics.map((tactic, index) => {
											return (
												<li key={index + 1}>
													<span className="inline-block">
														{tactic.title}
													</span>
													{tactic.trigger.type ===
														"callback" && (
														<span>
															(
															{
																process.env
																	.REACT_APP_BACKEND
															}
															webhook/
															{tactic.trigger.id})
														</span>
													)}
													{tactic.trigger.type ===
														"time" && (
														<span>
															(execution time -
															{
																tactic.trigger
																	.payload
															}
															)
														</span>
													)}
												</li>
											);
										})}
									</ol>
								</Fragment>
							)}
							{pendingTactics.length < 1 && (
								<p className="text-xs">No pending tactics</p>
							)}
						</div>
					</div>
					<div className="w-1/3">
						<div>
							<h4>Pending Orders</h4>
							{pendingOrders.length < 1 && (
								<p className="text-xs">No pending orders</p>
							)}
							<ol className="list-decimal list-inside text-xs">
								{pendingOrders.map((order, index) => {
									return (
										<li key={index + 1}>
											{order.entry} {order.intent} at{" "}
											{order.type} - {order.duration} (
											{order.abSize
												? order.abSize + "units"
												: order.pctSize + "%"}
											)
										</li>
									);
								})}
							</ol>
						</div>
						<div className="mt-4">
							<h4>Order Status/History</h4>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Details;

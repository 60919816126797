import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { useGlobal } from "reactn";
import Axios from "axios";
import ClosedPositionsRow from "./closed-positions-row";
import { toast } from "react-toastify";
import { groupBy } from "lodash";

const ClosedPositions = function () {
	const [reviewed, setReviewed] = useState();
	const [closedPositions, setClosedPositions] = useState([]);
	const [partialClosedPositions, setPartialClosedPositions] = useState([]);
	const [updateClosedPositions, setUpdateClosedPositions] = useGlobal(
		"updateClosedPositions"
	);
	const [closedPositionsDatesChanged, setClosedPositionsDatesChanged] = useState(
		false
	);
	const [closedPositionsDates, setClosedPositionsDates] = useState("");

	useEffect(() => {
		Axios.get(process.env.REACT_APP_BACKEND + "closed-positions")
			.then((response) => {
				console.log(response.data);
				setClosedPositions(response.data.full);
				setPartialClosedPositions(response.data.partial);
			})
			.catch((error) => {
				toast.error("There was a problem fetching closed positions");
				console.log("Error Fetching Closed Positions", error);
				setClosedPositions([]);
			});
	}, [updateClosedPositions, closedPositionsDatesChanged]);

	return (
		<>
			<div className="pt-2">
				{!closedPositions && (
					<p className="text-xs italic">
						Select a date to see the closed positions on that date
					</p>
				)}
				{closedPositions && (
					<>
						<div id="positions" className="mt-2">
							<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
								<div className="table min-w-full">
									<div className="table-header-group">
										<div className="table-row">
											<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
												Asset
											</div>
											<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
												Avg Entry
											</div>
											<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
												Avg Exit
											</div>
											<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
												P/L
											</div>
										</div>
									</div>
									{(closedPositions.length < 1 || partialClosedPositions.length < 1) && (
										<p className="text-xs m-2 italic">
											No closed positions for this date
										</p>
									)}
									{closedPositions.length > 0 && (
										<div className="table-row-group uppercase text-xs font-semibold">
											<p className="ml-2 my-1">Fully Closed</p>
										</div>
									)}
									<div className="table-row-group bg-white">
										{closedPositions.map((position) => {
											return (
												<ClosedPositionsRow
													key={"position-" + position.id}
													position={position}
												/>
											);
										})}
									</div>
									{partialClosedPositions.length > 0 && (
										<div className="table-row-group uppercase text-xs font-semibold">
											<p className="ml-2 my-1">Partially Closed</p>
										</div>
									)}
									<div className="table-row-group bg-white">
										{partialClosedPositions.map((position) => {
											return (
												<ClosedPositionsRow
													key={"position-" + position.id}
													position={position}
												/>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default ClosedPositions;

import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { Input } from "@chakra-ui/react";
import { toast } from "react-toastify";

import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
} from "@chakra-ui/react";
import Axios from "axios";

const Topbar = function (props) {
	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");
	const [selectedList, setSelectedList] = useGlobal("selectedWatchlist");
	const [updateList, setUpdateList] = useGlobal("updateList");
	const [customWatchlist, setCustomWatchlist] = useState();
	const [symbol, setSymbol] = useState();
	const [updateCategories, setUpdateCategories] = useGlobal("updateCategories");
	const addSymbolToWatchlist = function (e) {
		e.preventDefault();

		if (!selectedList) {
			toast.error("You must select a list before you can add a symbol");
			return;
		}
		const listSymbol = {
			watchlistId: selectedList.id,
			symbol: symbol,
		};

		Axios.post(process.env.REACT_APP_BACKEND + "watchlists_symbols", listSymbol)
			.then((response) => {
				toast.success(
					symbol + " was successfully added to watchlist: " + selectedList.name
				);
				console.log(response);
				setUpdateList((updateList) => !updateList);
				setSymbol("");
			})
			.catch((error) => {
				toast.error("Symbol could not be added to the watchlist. Please try again");
				console.log(error);
			});
	};

	const createCustomWatchlist = function (e) {
		e.preventDefault();

		const watchlist = {
			name: customWatchlist,
			category: "custom",
		};

		Axios.post(process.env.REACT_APP_BACKEND + "watchlists", watchlist)
			.then((response) => {
				toast.success(customWatchlist + " watchlist was successfully created");
				console.log(response);
				setCustomWatchlist("");
				setUpdateCategories((updateCategories) => !updateCategories);
			})
			.catch((error) => {
				toast.error("Error creating watchlist");
				console.log("Watchlist not created", error);
			});
	};

	const handleSymbol = function (e) {
		let typedSymbol = e.target.value;
		setSymbol(typedSymbol.toUpperCase());
	};

	const handleCustomWatchlist = function (e) {
		setCustomWatchlist(e.target.value);
	};

	useEffect(() => {}, []);

	return (
		<>
			<div className="flex justify-between">
				<div className="flex">
					<Popover>
						<PopoverTrigger>
							<Button
								size="xs"
								colorScheme="blue"
								className="mr-2"
								disabled={!selectedList ? "disabled" : ""}
							>
								<FaPlus className="pr-1" />
								Symbol
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader className="text-xs italic">
								Add symbol to{" "}
								<span className="font-semibold">
									{selectedList && selectedList.name}
								</span>{" "}
								watchlist
							</PopoverHeader>
							<PopoverBody>
								<form onSubmit={addSymbolToWatchlist}>
									<div className="flex">
										<div className="w-1/2 mr-2">
											<Input
												placeholder="Type Symbol"
												size="xs"
												onChange={handleSymbol}
												value={symbol}
											/>
										</div>
										<div className="w-1/2">
											<Button type="submit" size="xs">
												Add
											</Button>
										</div>
									</div>
								</form>
							</PopoverBody>
						</PopoverContent>
					</Popover>

					<Button size="xs" colorScheme="blue" variant="outline" className="">
						Bulk
					</Button>
				</div>
				<div className="flex">
					<Popover>
						<PopoverTrigger>
							<Button colorScheme="blue" size="xs" variant="outline">
								<FaPlus />
								List
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader className="text-xs italic">
								Give your custom watchlist a unique name
							</PopoverHeader>
							<PopoverBody>
								<form onSubmit={createCustomWatchlist}>
									<div className="flex">
										<div className="w-full mr-2">
											<Input
												placeholder="e.g. Cramer Mad Money..."
												size="xs"
												className="text-sm p-1 "
												onChange={handleCustomWatchlist}
												value={customWatchlist}
											/>
										</div>
										<div className="">
											<Button size="xs" type="submit">
												Add
											</Button>
										</div>
									</div>
								</form>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</div>
			</div>
		</>
	);
};

export default Topbar;

import React, { useState, Fragment } from "react";
import { FaTimes } from "react-icons/fa";

const Modal = function ({ children }) {
  const [showModal, setShowModal] = useState(true);
  const hideModal = function (e) {
    setShowModal(false);
  };
  window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <Fragment>
      {showModal && (
        <div>
          <div className="modal-bg fixed top-0 left-0 w-full h-full opacity-75 bg-black"></div>
          <div className="modal-content absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <div className="w-4/5 m-auto">
              <div className="flex justify-end text-red-800 z-50">
                <span
                  className="rounded-full block bg-gray-200 text-2xl cursor-pointer"
                  onClick={hideModal}
                >
                  <FaTimes />
                </span>
              </div>
              <div className="bg-white w-full p-4 opacity-100">{children}</div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Modal;

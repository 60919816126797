import React from "react";
import Draggable from "react-draggable";
import { useGlobal } from "reactn";
import { FaTimes, FaBars } from "react-icons/fa";

const PromptBox = function (props) {
	const { status, id, toggle, title } = props;
	const [closePrompt, setClosePrompt] = useGlobal(toggle);

	const handleClosePrompt = function () {
		setClosePrompt(false);
	};
	return (
		<Draggable handle=".draghandle">
			<div className="order-template bg-gray-700 text-xs absolute m-2 z-30">
				<div className="flex justify-between">
					<span
						title="Drag Panel"
						className="draghandle text-gray-400 cursor-move p-1 bg-gray-800"
					>
						<FaBars />
					</span>
					<span className="mx-2 text-xs uppercase text-gray-400 pt-1 font-bold">
						{title}
					</span>
					<span
						onClick={handleClosePrompt}
						className="text-red-600 cursor-pointer p-1 bg-gray-800"
					>
						<FaTimes />
					</span>
				</div>
				<div className="text-white">{props.children}</div>
			</div>
		</Draggable>
	);
};

export default PromptBox;

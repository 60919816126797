import React from "react";
import { setGlobal } from "reactn";
import ReactDOM from "react-dom";
import "./css/index.css";
import "./tailwind.css";
import App from "./App";
import { tactics } from "./services/fakedata";
import addReactNDevTools from "reactn-devtools";
import initReactnPersist from "reactn-persist";
import { ChakraProvider } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";

//Default settings for the global state
//TODO: Save/load into/from localstorage
setGlobal({
	tactics: [],
	appliedTactics: [],
	updateTactics: false,
	syncingTactics: 0,

	brokers: {},
	selectedBroker: "tradierPaper",
	updateBrokers: false,
	fetchingBrokers: false,

	assets: {},
	selectedAsset: "AMD",
	selectedAssetClass: "equity",
	selectedOptionUnderlying: "",
	selectedAssetQuote: {
		last: 0,
		bid: 0,
		ask: 0,
	},
	updateAssets: false,
	fetchingAssets: false,

	selectedOption: {},

	positions: [],
	selectedPosition: "",
	updatePositions: false,
	fetchingPositions: false,

	orders: [],
	updateOrders: false,
	brokerOrders: [],
	updateBrokerOrders: false,
	syncingOrderTemplates: 0,

	quickOrderToggle: false,

	slidePanelControl: false,
});

addReactNDevTools();

// initReactnPersist({
// 	storage: sessionStorage,
// });

function AppTopLevel() {
	return (
		<ChakraProvider>
			<App />
		</ChakraProvider>
	);
}

ReactDOM.render(<AppTopLevel />, document.getElementById("root"));

import io from "socket.io-client";

const socket = io(process.env.REACT_APP_BACKEND, {
	transports: ["websocket"],
});

socket.on("connect", () => {
	console.log("Connected to backend socket");
});

export default socket;

//React and Libraries
import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { useGlobal } from "reactn";
import Axios from "axios";
//Layouts
import Header from "./components/layout/header";
//Functional Components
import Tactics from "./components/functional/tactics/";
import Dashboard from "./components/functional/dashboard/";
import Analytics from "./components/functional/analytics";
import Tribes from "./components/functional/tribes";
import Account from "./components/functional/account";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Montage from "./components/functional/montage/";

function App() {
	const { 0: updateBrokers } = useGlobal("updateBrokers");
	const { 1: setBrokers } = useGlobal("brokers");
	const { 0: updateAssets } = useGlobal("updateAssets");
	const { 1: setAssets } = useGlobal("assets");
	const { 0: updatePositions } = useGlobal("updatePositions");
	const { 1: setPositions } = useGlobal("positions");
	const { 0: updateOrders } = useGlobal("updateOrders");
	const { 1: setOrders } = useGlobal("orders");
	const { 0: updateBrokerOrders } = useGlobal("updateBrokerOrders");
	const { 1: setBrokerOrders } = useGlobal("brokerOrders");
	const { 1: setFetchingBrokers } = useGlobal("fetchingBrokers");
	const { 1: setFetchingAssets } = useGlobal("fetchingAssets");
	const { 0: selectedAsset } = useGlobal("selectedAsset");
	const { 1: setOptionUnderlying } = useGlobal("optionUnderlying");
	const [pendingOrders, setPendingOrders] = useGlobal("pendingOrders");

	//Load or update brokers and assets
	useEffect(() => {
		setFetchingBrokers(true);
		async function fetchBrokers() {
			await Axios.get(process.env.REACT_APP_BACKEND + "brokers")
				.then((response) => {
					setBrokers(response.data);
					setFetchingBrokers(false);
				})
				.catch((errata) => toast.error("Error loading brokers"));
		}
		fetchBrokers();
		setFetchingBrokers(false);
	}, [updateBrokers]);

	useEffect(() => {
		setFetchingAssets(true);
		async function fetchAssets() {
			await Axios.get(process.env.REACT_APP_BACKEND + "assets")
				.then((response) => {
					setAssets(response.data);
					setFetchingAssets(false);
				})
				.catch((errata) => toast.error("Error loading assets"));
		}
		fetchAssets();
		setFetchingAssets(false);
	}, [updateAssets]);

	useEffect(() => {
		async function fetchPositions() {
			await Axios.get(process.env.REACT_APP_BACKEND + "positions")
				.then((response) => {
					setPositions(response.data);
					toast.success("Positions refreshed");
				})
				.catch((errata) => toast.error("Error loading positions"));
		}
		fetchPositions();
	}, [updatePositions]);

	//Global Effects. These typically runs after a setState has been done with Reactn useGlobal[1]
	useEffect(() => {
		if (selectedAsset.length > 6) {
			let occPattern = /(\w{1,6})(\d{6})([cp])(\d{8})/gi;
			let matches = occPattern.exec(selectedAsset);
			console.log(matches);
			setOptionUnderlying(matches[1]);
		} else {
			setOptionUnderlying(undefined);
		}
	}, [selectedAsset]);

	useEffect(() => {
		async function fetchOrders() {
			await Axios.get(process.env.REACT_APP_BACKEND + "orders?sort=-updatedAt")
				.then((response) => {
					setOrders(response.data);
					toast.success("App orders refreshed");
				})
				.catch((errata) => toast.error("Error loading app orders"));
		}
		fetchOrders();
	}, [updateOrders]);

	useEffect(() => {
		async function fetchPendingOrders() {
			Axios.get(
				process.env.REACT_APP_BACKEND + "orders?sort=-updatedAt&brokorderId="
			)
				.then((response) => setPendingOrders(response.data))
				.catch((errata) => toast.error("could not fetch pending orders"));
		}
	}, [updatePositions, updateOrders]);

	useEffect(() => {
		async function fetchBrokerOrders() {
			await Axios.get(process.env.REACT_APP_BACKEND + "broker-orders")
				.then((response) => {
					setBrokerOrders(response.data);
				})
				.catch((errata) => toast.error("Error loading broker orders"));
		}
		fetchBrokerOrders();
	}, [updateOrders]);

	return (
		<Fragment>
			<BrowserRouter>
				<Header />
				<div className="bg-gray-100 pt-6 h-auto">
					<div id="content" className="max-w-6xl mx-auto sm:px-6 lg:px-8 h-full">
						<Route exact path="/" component={Dashboard} />
						<Route exact path="/montage" component={Montage} />
						<Route exact path="/tactics" component={Tactics} />
						<Route exact path="/analytics" component={Analytics} />
						<Route exact path="/tribes" component={Tribes} />
						<Route exact path="/account" component={Account} />
					</div>
				</div>
			</BrowserRouter>
			<div className="z-50">
				<ToastContainer />
			</div>
		</Fragment>
	);
}

export default App;

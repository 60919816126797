import React, { useState, useRef, useEffect } from "react";
import { Select } from "@chakra-ui/react";
import { useGlobal } from "reactn";

const CatSelect = function (props) {
	const [selectedList, setSelectedList] = useGlobal("selectedList");
	const selectRef = useRef(props.category);

	// useEffect(() => {
	// 	if (selectedList) {
	// 		if (selectedList.category != props.category) {
	// 			selectRef && (selectRef.current.value = undefined);
	// 		}
	// 	}
	// }, [selectedList]);
	return (
		<>
			<div className="text-xs uppercase font-semibold mb-0">{props.category}</div>
			<div className="">
				<Select
					size="xs"
					className="text-xs pl-1 py-1"
					placeholder="Select"
					onChange={props.handleListSelect}
					ref={selectRef}
				>
					{props.lists
						.filter((list) => list.category == props.category)
						.map((list) => {
							return (
								<option key={list.id} value={list.category + ":" + list.id}>
									{list.name}
								</option>
							);
						})}
				</Select>
			</div>
		</>
	);
};

export default CatSelect;

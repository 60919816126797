import React from "react";
import SmallOrders from "./small-orders";

const Orders = function (props) {
	return (
		<React.Fragment>{props.size === "small" && <SmallOrders />}</React.Fragment>
	);
};

export default Orders;

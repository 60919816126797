const populateRule = function (
	tactic,
	triggerStatus,
	asset,
	assets,
	broker,
	assetClass
) {
	let realtimeTriggers = ["now", "button", "hotkey"];
	let rule = {};
	rule.title = tactic.title;
	rule.asset = asset;
	rule.tacticId = tactic.id;
	rule.trigger = {
		type: tactic.trigtemp.type,
		title: tactic.trigtemp.title,
		payload: tactic.trigtemp.payload,
		asset: tactic.trigtemp.asset,
		status: triggerStatus,
		trigplateId: tactic.trigtempId,
	};
	rule.trigger.id = 0;
	rule.trigger.trigplateId = tactic.trigtempId;
	rule.orders = [];
	tactic.tactic_ordtemps.map((ordtemp) => {
		let order = {
			broker: broker,
			asset: asset,
			action: ordtemp.action,
			intent: ordtemp.intent,
			type: ordtemp.type,
			duration: ordtemp.duration,
			price: ordtemp.price,
			abSize: ordtemp.action === "entry" ? ordtemp.size : null,
			pctSize: ordtemp.action === "exit" ? ordtemp.size : null,
			status: realtimeTriggers.includes(tactic.trigtemp.type)
				? "pending"
				: "inactive",
			statusDetails: realtimeTriggers.includes(tactic.trigtemp.type)
				? "order pending"
				: "order added",
			ordplateId: ordtemp.id,
			tacplateId: tactic.id,
			trigplateId: tactic.trigtempId,
			assetClass: assetClass,
		};

		rule.orders.push(order);
	});
	return rule;
};

export default populateRule;

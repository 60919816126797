import React, { useState } from "react";
import { useGlobal } from "reactn";

import { useEffect } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import CatSelect from "./categories-select";

const Categories = function () {
	const [selectedList, setSelectedList] = useGlobal("selectedWatchlist");
	const [updateCategories, setUpdateCategories] = useGlobal("updateCategories");
	const [lists, setLists] = useState();

	const handleListSelect = function (event) {
		const listArray = event.target.value.split(":");
		setSelectedList({
			category: listArray[0],
			id: listArray[1],
			name: event.target.options[event.target.selectedIndex].text,
		});
	};

	useEffect(() => {
		Axios.get(process.env.REACT_APP_BACKEND + "watchlists")
			.then((response) => setLists(response.data))
			.catch((error) => {
				toast.error("There was a problem fetching lists");
				console.log("Error Fetching Watchlist Categories:", error);
			});
	}, [updateCategories]);

	if (!lists)
		return (
			<p className="text-xs italic">
				List categories will be displayed here once loaded
			</p>
		);

	return (
		lists && (
			<div className="flex justify-between ">
				<div id="calendar" className="w-1/3 p-1 mr-1 bg-gray-200">
					<CatSelect
						lists={lists}
						handleListSelect={handleListSelect}
						category={"calendar"}
					/>
				</div>
				<div id="tags" className="w-1/3 p-1 mr-1 bg-gray-200 hover:bg-gray-300">
					<CatSelect
						lists={lists}
						handleListSelect={handleListSelect}
						category={"tags"}
					/>
				</div>
				<div id="custom" className="w-1/3 p-1 mr-1 bg-gray-200 hover:bg-gray-300">
					<CatSelect
						lists={lists}
						handleListSelect={handleListSelect}
						category={"custom"}
					/>
				</div>
			</div>
		)
	);
};

export default Categories;

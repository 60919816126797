import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";

const ListRow = function (props) {
	const { listItem } = props;
	const [selectedListItem, setSelectedListItem] = useState({
		id: 0,
	});

	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");

	const handleSymbol = function (listItem, e) {
		setSelectedListItem(listItem);
		setSelectedAsset(listItem.symbol);
	};

	return (
		<div
			className={
				(selectedListItem.id === listItem.id
					? "bg-yellow-200 hover:bg-yellow-200"
					: "hover:bg-yellow-100 active:bg-yellow-100") +
				" table-row cursor-pointer text-xs"
			}
			//onContextMenu={setContextMenu(menuConfig)}
			onClick={(e) => handleSymbol(listItem, e)}
			//onDoubleClick={handleNotesPanel}
		>
			<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>{listItem.symbol}</div>
			</div>
			<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>3.45</div>
			</div>
			<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>{listItem.reviewed ? "Yes" : "No"}</div>
			</div>
			<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
				<div>{listItem.comment ? listItem.comment : "No Comment"}</div>
			</div>
		</div>
	);
};

export default ListRow;

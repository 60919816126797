import React from "react";

function Tribes() {
  return (
    <div>
      <h2 className="mb-4 text-2xl">Tribes</h2>
      <p>
        User will be able to join trading group and share tactics with them{" "}
      </p>
    </div>
  );
}

export default Tribes;

import React from "react";
import reactn from "reactn";
import OrderTicket from "../functional/orders/order-ticket";
import { Link } from "react-router-dom"

const Header = function () {
	const [showQuickOrder, setShowQuickOrder] = reactn.useGlobal(
		"quickOrderToggle"
	);
	const handleQuickOrder = function () {
		setShowQuickOrder(true);
	};
	return (
		<>
			<div>
				<nav className="flex items-center justify-between flex-wrap bg-gray-800 p-4">
					<div className="flex items-center flex-shrink-0 text-white mr-6">
						<span className="font-semibold text-xl tracking-tight">
							Trade Tactics
						</span>
					</div>
					<div className="block lg:hidden">
						<button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
							<svg
								className="fill-current h-3 w-3"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<title>Menu</title>
								<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
							</svg>
						</button>
					</div>
					<div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
						<div className="text-sm lg:flex-grow">
							<Link
								to="/"
								className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
							>
								Dashboard
							</Link>

              <Link
								to="/montage"
								className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
							>
								Montage
							</Link>

							<Link
								to="/tactics"
								className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
							>
								Tactics
							</Link>
							<Link
								to="/analytics"
								className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
							>
								Analytics
							</Link>
							<Link
								to="/tribes"
								className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
							>
								Tribes
							</Link>
							<Link
								to="/account"
								className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white"
							>
								Account
							</Link>
						</div>
						<div>
							<button className="bg-teal-500 rounded-sm antialiased border border-teal-500 tracking-widest hover:shadow hover:bg-teal-400 hover:border-teal-400 text-xs font-semibold uppercase p-1 px-4 ml-3">
								Apply Tactics
							</button>
							<button
								onClick={handleQuickOrder}
								className="bg-yellow-500 rounded-sm antialiased border border-yellow-500 tracking-widest hover:shadow hover:bg-yellow-400 hover:border-yellow-400 text-xs font-semibold uppercase p-1 px-4 ml-3"
							>
								Quick Order
							</button>
							{showQuickOrder && (
								<OrderTicket
									status="open"
									id="quick-order"
									toggle="quickOrderToggle"
								/>
							)}
						</div>
					</div>
				</nav>
			</div>
		</>
	);
};

export default Header;

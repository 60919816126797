import React from "react";
import Axios from "axios";
import { useEffect, useGlobal } from "reactn";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const Notes = function () {
	const [newNote, setNewNote] = React.useState();
	const [notes, setNotes] = React.useState();
	const [refreshNotes, setRefreshNotes] = React.useState(false);
	const [selectedAsset] = useGlobal("selectedAsset");
	const { 0: optionUnderlying } = useGlobal("optionUnderlying");

	const handleNote = function (e) {
		e.preventDefault();
		setNewNote(e.target.value);
	};

	const addNote = function (e) {
		//The note data is submitted at this point
		e.preventDefault();

		const noteData = {
			asset: optionUnderlying || selectedAsset,
			content: newNote,
		};

		Axios.post(`${process.env.REACT_APP_BACKEND}notes`, noteData)
			.then((response) => {
				console.log(response.data);
				toast.success("Toast successfully saved", { position: "top-left" });
				setRefreshNotes(!refreshNotes);
			})
			.catch((errata) => toast.error(errata.error));
	};

	useEffect(() => {
		const getNotes = () => {
			Axios.get(
				`${process.env.REACT_APP_BACKEND}notes?asset=${
					optionUnderlying || selectedAsset
				}`
			).then((response) => setNotes(response.data));
		};
		getNotes();
	}, [optionUnderlying, selectedAsset, refreshNotes]);

	return (
		<>
			<form>
				<textarea
					name="note"
					value={newNote}
					className="w-full text-gray-800 p-1 h-40 outline-none focus:shadow-outline"
					onChange={handleNote}
				></textarea>
				<div className="flex justify-end mb-2">
					<button
						className="m-1 cursor-pointer text-sm bg-600-400 rounded p-1 px-1 hover:bg-yellow-400"
						onClick={addNote}
					>
						Add Note
					</button>
				</div>
				<div>
					{notes &&
						notes.map((note) => {
							return (
								<div className="bg-gray-400 mb-2 p-1" key={note.id}>
									<p className="text-sm">{note.content}</p>
									<div className="flex justify-end text-xs">
										<p>{dayjs(note.createdAt, "YYYY-MM-DD").format("MMM D YYYY")}</p>
									</div>
								</div>
							);
						})}
				</div>
			</form>
		</>
	);
};

export default Notes;

import React from "react";
import { useEffect, useGlobal } from "reactn";
import NumberFormat from "react-number-format";
import usePagination from "use-pagination";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const Smallorders = function () {
	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");
	const [selectedBroker, setSelectedBroker] = useGlobal("selectedBroker");
	const [selectedOrder, setSelectedOrder] = useGlobal("selectedOrder");

	const [brokerOrders, setBrokerOrders] = useGlobal("brokerOrders");
	const [orders, setOrders] = useGlobal("orders");
	const [updateOrders, setUpdateOrders] = useGlobal("updateOrders");

	const reset = React.useRef();
	const bOrders = brokerOrders;

	const pagination = usePagination({
		items: orders,
		itemsPerPage: 10,
	});

	const handleOrder = function (order, e) {
		//e.preventDefault();
		setSelectedOrder(order);
		setSelectedAsset(order.asset);
		setSelectedBroker(order.broker);
	};

	return (
		<React.Fragment>
			{/* <div className="flex justify-between text-xs px-2">
				<button onClick={pagination.onPreviousPage}>&laquo; Back</button>
				<button ref={reset} onClick={pagination.onResetPage}>
					Reset
				</button>
				<button onClick={pagination.onNextPage}>Next &raquo; </button>
			</div> */}

			<div className="overflow-x-auto text-xs m-1 p-2">
				<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
					<div className="table min-w-full">
						<div className="table-header-group">
							<div className="table-row">
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Asset
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Details
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									App
								</div>
								<div className="table-cell px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									Broker
								</div>
							</div>
						</div>
						<div className="table-row-group bg-white">
							<React.Fragment>
								{orders.map((order) => {
									if (order.asset.length > 6) {
										let occPattern = /(\w{1,6})(\d{6})([cp])(\d{8})/gi;
										let matches = occPattern.exec(order.asset);

										order.optionUnderlying = matches[1];
										order.optionExpiry = dayjs(matches[2], "YYMMDD").format("MMM D YYYY");
										// let optionType = matches[3] === "C" ? "Call" : "Put";
										// position.optionType =
										// 	position.size > 1 ? optionType + "s" : optionType;
										order.optionType = matches[3];
										order.optionStrike = matches[4];
									}

									let brokerOrderStatus = "NR";
									let brokerOrderUpdatedAt = "";
									if (order.statusDetails.startsWith("BrokerOrderId")) {
										const sd = order.statusDetails.split(":");
										const brokerOrderId = sd[1];
										//console.log(brokerOrders);
										//console.log("BrokerOrderId is " + brokerOrderId);
										if (bOrders.length > 0) {
											const brokerOrder = bOrders.find(
												(bOrder) => bOrder.id == brokerOrderId
											);

											//console.log(brokerOrder);
											if (brokerOrder) {
												brokerOrderStatus = brokerOrder.status;
												brokerOrderUpdatedAt = brokerOrder.updatedAt;
											}
										}
									}

									return (
										<div
											className={" table-row cursor-pointer"}
											key={order.id}
											onClick={(e) => handleOrder(order, e)}
										>
											<div className="table-cell pl-2 py-2 whitespace-no-wrap border-b border-gray-200">
												<div>
													{order.optionUnderlying || order.asset}{" "}
													{order.optionType && (
														<span
															className={`${
																order.optionType === "C" ? "bg-blue-200" : "bg-purple-200"
															} inline-block px-1 rounded-sm`}
														>
															{order.optionType}-
															<NumberFormat
																value={order.optionStrike / 1000}
																displayType="text"
																thousandSeparator
																decimalScale={2}
																prefix="$"
															/>
														</span>
													)}{" "}
												</div>
												<div
													className={`${
														order.optionType === "C" ? "text-blue-500" : "text-purple-500"
													}`}
												>
													{order.optionExpiry}
												</div>
												<div className=" text-gray-600">{order.broker}</div>
											</div>
											<div className="table-cell pl-1 py-2 whitespace-no-wrap border-b border-gray-200">
												<div>
													{order.action[1]}-{order.intent[0]}{" "}
													<span title="quantity">
														({order.abSize ? order.abSize : order.pctSize + "%"})
													</span>
												</div>
												<div title="total entry value" className="text-gray-600">
													{order.type[0]}
													{order.stop ? "@" + order.price : "@" + order.stop}-
													{order.duration}
												</div>
											</div>
											<div className="table-cell pl-1 py-2 whitespace-no-wrap border-b border-gray-200">
												<div>
													{order.status === "placed-with-broker" ? "placed" : order.status}
												</div>
												<div title="total current value" className="text-gray-600">
													{dayjs(new Date(order.updatedAt)).format("H:mm,M/D")}
												</div>
											</div>
											<div className="table-cell pr-2 pl-1 py-2 whitespace-no-wrap border-b border-gray-200">
												<div>{brokerOrderStatus}</div>
												<div title="Total PnL" className="text-gray-600">
													{brokerOrderUpdatedAt !== ""
														? dayjs(new Date(brokerOrderUpdatedAt)).format("H:mm,M/D")
														: ""}
												</div>
											</div>
										</div>
									);
								})}
							</React.Fragment>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Smallorders;

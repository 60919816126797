import React from "react";
import { useGlobal } from "reactn";
import isEmpty from "lodash.isempty";

const Brokers = function (props) {
	const [brokers] = useGlobal("brokers");
	const [selectedBroker, setSelectedBroker] = useGlobal("selectedBroker");
	const [fetchingBrokers] = useGlobal("fetchingBrokers");
	const [assets] = useGlobal("assets");
	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");
	const [showQuoteOptions, setShowQuoteOptions] = React.useState(false);
	const [quote, setQuote] = React.useState("BTC");

	let brokersArr = Object.entries(brokers);
	let cryptoBrokers = [];
	brokersArr.map((value) => {
		if (value[1].type == "crypto") cryptoBrokers.push(value[0]);
	});
	const handleBroker = function (e) {
		const value = e.target.value;
		if (selectedBroker !== value) {
			if (!assets[value] || !assets[value].includes(selectedAsset)) {
				setSelectedAsset("");
			}
			setSelectedBroker(value);
			setShowQuoteOptions(cryptoBrokers.includes(value));
		}
	};

	// if (brokers[selectedBroker].type == "crypto") {
	// 	return <Text>USDT, BTC, </Text>;
	// 	// For Binance, load USDT, BTC, and BNB balances
	// 	// For the others, load USDT and BTC balances
	// }

	const handleCryptoQuote = function (e) {
		setQuote(e.target.value);
	};

	React.useEffect(() => {
		if (cryptoBrokers.includes(selectedBroker)) {
			setSelectedAsset(selectedAsset + "/" + quote);
		}
	}, [brokers, selectedBroker, quote, selectedAsset]);

	return (
		<div className="flex flex-row align-middle items-center">
			<select
				className={props.styling + " flex-grow"}
				onChange={handleBroker}
				value={selectedBroker}
			>
				<option key="0" value="">
					{fetchingBrokers ? "Fetching Brokers..." : "Select a broker"}
				</option>
				{brokersArr.map((value, key) => {
					return (
						<option key={key + 1} value={value[0]}>
							{value[1].name}
						</option>
					);
				})}
			</select>
			{showQuoteOptions && (
				<div
					className="flex text-sm align-middle items-center"
					onChange={handleCryptoQuote}
				>
					<label>
						<input type="radio" value="BTC" name="quote" checked={quote === "BTC"} />{" "}
						BTC
					</label>
					<label className="ml-1">
						<input
							type="radio"
							value="USDT"
							name="quote"
							checked={quote === "USDT"}
						/>{" "}
						USDT
					</label>
				</div>
			)}
		</div>
	);
};

export default Brokers;

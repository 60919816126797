import React from "react";
import { useEffect, useGlobal } from "reactn";
import PositionTactics from "../tactics/position-tactics";
import Axios from "axios";
import validateAsset from "../../helpers/validate-asset";
import populateRule from "../../helpers/populate-rule";
import { toast } from "react-toastify";
import Mousetrap from "mousetrap";
import { isEmpty, sortBy } from "lodash";
import Positions from "../../common/positions";
import Orders from "../../common/orders";
import InflectionPoints from "./inflection-points";
import Assets from "../../common/assets";
import Brokers from "../../common/brokers";
import SlidePanel from "../../common/slide-panel";
import Notes from "../notes";
import { FaSync } from "react-icons/fa";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Watchlists from "../../common/watchlists";
import ClosedPositions from "../../common/positions/closed/closed-positions";
import PendingPositions from "../../common/positions/pending";

const Montage = function () {
	const [buttonTactics, setButtonTactics] = React.useState([]);
	const [hotkeyTactics, setHotkeyTactics] = React.useState([]);

	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");
	const { 0: optionUnderlying } = useGlobal("optionUnderlying");
	const [assets, setAssets] = useGlobal("assets");
	const [selectedBroker, setSelectedBroker] = useGlobal("selectedBroker");
	const [selectedAssetClass, setSelectedAssetClass] = useGlobal(
		"selectedAssetClass"
	);
	const [updatePositions, setUpdatePositions] = useGlobal("updatePositions");
	const [updateOrders, setUpdateOrders] = useGlobal("updateOrders");

	useEffect(() => {
		const fetchButtonTactics = function () {
			Axios.get(`${process.env.REACT_APP_BACKEND}tactics?trigtemp.type=button`)
				.then((response) => setButtonTactics(response.data))
				.catch((errata) => console.log(errata.message));
		};
		fetchButtonTactics();
	}, [1]);

	useEffect(() => {
		const fetchHotkeyTactics = function () {
			Axios.get(`${process.env.REACT_APP_BACKEND}tactics?trigtemp.type=hotkey`)
				.then((response) => setHotkeyTactics(response.data))
				.catch((errata) => console.log(errata.message));
		};
		fetchHotkeyTactics();
	}, [1]);

	const handleTactic = async function (trigtempId, e = {}) {
		if (!validateAsset(selectedAsset, assets, selectedBroker)) return false;
		if (!selectedAsset && !selectedBroker) {
			toast.error("Please provide a valid broker and asset");
		} else {
			try {
				let response = await Axios.get(
					`${process.env.REACT_APP_BACKEND}tactics?trigtempId=${trigtempId}`
				);
				let tactic = response.data[0];
				let rule = populateRule(
					tactic,
					"triggered",
					selectedAsset,
					assets,
					selectedBroker,
					selectedAssetClass
				);
				console.log("Order is about to be placed");
				//console.log(rule);
				const orderResponse = await Axios.post(
					process.env.REACT_APP_BACKEND + "rules",
					rule
				);
				console.log(orderResponse.data);
				toast.success(
					"Order successfully placed for " +
						selectedAsset +
						" with " +
						selectedBroker
				);
				setTimeout(() => {
					setUpdatePositions(!updatePositions);
					setUpdateOrders(!updateOrders);
				}, 900);
			} catch (e) {
				toast.error("There was an error placing the order " + e.message);
			}
		}
	};

	return (
		<React.Fragment>
			<h2 className="mb-4 text-2xl">Trade Montage</h2>
			<div className="flex flex-wrap md:flex-no-wrap">
				<div className="w-full md:w-2/3 mr-4 p-2 md:p-1 lg:p-0 ">
					<p className="mb-4">
						The montage is bigger space for placing trades and applying tactics using
						preconfigured button rules
					</p>
					<div className="flex flex-wrap sm:flex-no-wrap">
						<div className="md:w-3/5 mr-2 ">
							<div className="py-5">
								<h3 className="font-semibold">
									Positions{" "}
									<span
										title="refresh"
										onClick={() => setUpdatePositions(!updatePositions)}
										className="text-xs hover:text-green-600 p-1 text-gray-600 cursor-pointer inline-block "
									>
										<FaSync />
									</span>
								</h3>
								<Tabs
									className="mb-4"
									size="sm"
									variant="enclosed"
									_hover={"bg-blue-100"}
									isFitted
									isLazy
									defaultIndex={3}
								>
									<TabList>
										<Tab>Open</Tab>
										<Tab>Closed</Tab>
										<Tab>Pending</Tab>
										<Tab>Watchlists</Tab>
									</TabList>
									<TabPanels>
										<TabPanel className="p-0">
											<Positions className="-m-1 mt-1" size="small" />
										</TabPanel>
										<TabPanel>
											<ClosedPositions />
										</TabPanel>
										<TabPanel>
											<PendingPositions />
										</TabPanel>
										<TabPanel>
											<Watchlists />
										</TabPanel>
									</TabPanels>
								</Tabs>

								<div className="h-auto overflow-y-scroll "></div>
							</div>
							<div>
								<h3 className="font-semibold">
									Orders{" "}
									<span
										title="refresh"
										onClick={() => setUpdateOrders(!updateOrders)}
										className="text-xs hover:text-green-600 p-1 text-gray-600 cursor-pointer inline-block "
									>
										<FaSync />
									</span>
								</h3>
								<div className="h-auto overflow-y-scroll ">
									<Orders size="small" />
								</div>
							</div>
						</div>
						<div className="md:w-2/5 overflow-x-scroll ">
							<div className="bg-blue-100 mb-4 p-2">
								<Brokers styling="form-select text-xs h-10 py-1 mx-1 my-1 w-1/2" />
								<Assets
									styling="auto-complete-input form-input w-full m-1 h-10 text-xs"
									chain="normal"
								/>
								<p>{selectedAssetClass}</p>
							</div>
							<div>
								<h3 className="font-semibold">Button Tactics</h3>
								<div className="flex flex-wrap">
									{sortBy(buttonTactics, [
										function (t) {
											return t.trigtemp.payload;
										},
									]).map((tactic, index) => {
										return (
											<div key={index + 1} className="w-1/2 mt-2 ">
												<button
													className={
														(tactic.trigtemp.payload.toLowerCase().includes("long")
															? "bg-green-500 hover:bg-green-400"
															: tactic.trigtemp.payload.toLowerCase().includes("short")
															? "bg-red-500 hover:bg-red-400"
															: "bg-blue-500 hover:bg-blue-400") +
														" rounded-sm p-2 w-11/12 text-xs font-semibold"
													}
													onClick={(e) => handleTactic(tactic.trigtempId, e)}
												>
													{tactic.trigtemp.payload}
												</button>
											</div>
										);
									})}
								</div>
							</div>
							<div className="mt-4">
								<h3 className="font-semibold">Hotkey Tactics</h3>
								<div className="flex flex-wrap">
									{hotkeyTactics.map((tactic, index) => {
										Mousetrap.bind(
											tactic.trigtemp.payload,
											function (e) {
												e.preventDefault();
												handleTactic(tactic.trigtempId);
											},
											"keypress"
										);
										return (
											<div key={index + 1} className="w-1/2 mt-2 text-xs ">
												<div className=" bg-gray-500 w-11/12 text-center">
													sequence: {tactic.trigtemp.payload}
												</div>{" "}
												<div className="text-center w-11/12 bg-gray-300 rounded-b">
													{tactic.tactic_ordtemps[0].title}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-1/3 bg-gray-300 h-auto p-4">
					{/* <PositionTactics /> */}
					{/* <InflectionPoints /> */}
				</div>
			</div>
			<div className="relative z-10 overflow-scroll-y">
				<SlidePanel type="right" size={40} noBackdrop>
					<h3 className="font-semibold mb-2">
						{optionUnderlying || selectedAsset} Position
					</h3>
					<div className="flex">
						<div className="w-1/2 pr-2">
							<h4>Notes</h4>
							<Notes />
						</div>
						<div className="w-1/2">
							<h4>Details</h4>
						</div>
					</div>
				</SlidePanel>
			</div>
		</React.Fragment>
	);
};

export default Montage;

import React, {Fragment} from "react";
import useGlobal from "reactn";

function Analytics() {
  
  return (
    <Fragment>

    <div>
      <h2 className="mb-4 text-2xl">Analytics</h2>
      <p>Charts and Data, yada yada yada... </p>
    </div>
    </Fragment>

  );
}

export default Analytics;

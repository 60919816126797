import React, { Fragment } from "react";
import { useGlobal } from "reactn";
import uid from "uid";
import Axios from "axios";
import { FaTrash, FaPlusSquare, FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";

const Tactic = function (props) {
  const {
    tid,
    action,
    title,
    intent,
    quantity,
    ordTempTitle,
    context,
    posTacticsId,
    trigger,
  } = props;

  const [posTactics, setPosTactics] = useGlobal("appliedTactics");
  const [tactics, setTactics] = useGlobal("tactics");
  const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");

  const applyTactic = function (e) {
    e.preventDefault();
    let currentTactic = tactics.filter((tactic) => tactic.id === tid)[0];
    let currentTacticAsset = currentTactic.tactic_ordtemps[0].asset;
    currentTactic.posTacticsId = uid();

    if (currentTacticAsset === "any" && selectedAsset === "") {
      toast.error("Please select an asset before adding a generic tactic");
      return false;
    }
    if (posTactics.length < 1) {
      if (currentTacticAsset !== "any") {
        setSelectedAsset(currentTacticAsset);
      }
    }
    setPosTactics([...posTactics, currentTactic]);
  };

  const removePosTactic = function () {
    setPosTactics(
      posTactics.filter((tactic) => tactic.posTacticsId !== posTacticsId)
    );
  };

  const deleteTactic = function () {
    Axios.delete(process.env.REACT_APP_BACKEND + "tactics/" + tid).then((response) => {
      setTactics(tactics.filter((tactic) => tactic.id !== tid));
      setPosTactics(posTactics.filter((tactic) => tactic.id !== tid));
      toast.success("Tactic has been deleted successfully");
    });
  };

  const copyToClipboard = function () {
    copy(process.env.REACT_APP_BACKEND + "webhook/" + trigger.id);
    toast.success("Callback URL copied to clipboard");
  };

  return (
    <div className=" mt-4 bg-gray-200 text-xs shadow w-11/12 mx-auto">
      <div className="tactics-title bg-gray-600 p-2 text-white cursor-pointer">
        {title}
      </div>
      <div className="tactics-body">
        <div className="tactics-details">
          <div className="trigger p-2 bg-gray-400">
            <span className="block">Trigger: {trigger.title}</span>
            {context === "tacticsCommitted" && (
              <div className="inline-block block mb-2 w-full flex items-baseline">
                <span className="callback-url overflow-auto">
                  {process.env.REACT_APP_BACKEND}webhook/{trigger.id}
                </span>
                <span
                  className="inline-block ml-1 cursor-pointer"
                  title="Copy to clipboard"
                  onClick={copyToClipboard}
                >
                  <FaCopy />
                </span>
              </div>
            )}
          </div>

          <div className="p-2">
            <span
              className={
                (action == "entry"
                  ? "text-blue-800 bg-blue-100"
                  : "text-purple-800 bg-purple-100") +
                " text-xs px-2 mr-1 inline-flex rounded-full leading-5 font-semibold"
              }
            >
              {action} - {intent}
            </span>
            {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {quantity} units
          </span> */}
            <span className="block mt-2">{ordTempTitle}</span>
            <span className="block mt-2">Quantity: {quantity}</span>
            <span className="block mt-2">Tactics ID: {tid}</span>
            <span className="block mt-2">
              Position Tactics ID: {posTacticsId}
            </span>
          </div>
        </div>
        <div className="tactics-apply mt-4 flex justify-between p-2">
          {context === "posSelect" ? (
            <Fragment>
              <button></button>
              <button
                className="text-red-600 underline"
                onClick={removePosTactic}
              >
                Remove
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                onClick={deleteTactic}
                className="bg-teal-500 focus:outline-none rounded-sm antialiased border border-teal-500 tracking-widest hover:shadow hover:bg-teal-400 hover:border-teal-400 text-xs font-bold uppercase p-1 ml-3"
              >
                <FaTrash />
              </button>
              <button
                onClick={applyTactic}
                className="bg-teal-500 focus:outline-none rounded-sm antialiased border border-teal-500 tracking-widest hover:shadow hover:bg-teal-400 hover:border-teal-400 text-xs font-bold uppercase p-1 ml-3"
              >
                <FaPlusSquare className="text-lg" />
              </button>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tactic;

import React, { Fragment, useState } from "react";
import { useGlobal } from "reactn";
import { FaCaretDown, FaSync, FaGripLines } from "react-icons/fa";
import isNumber from "lodash/isNumber";
import Details from "./details";
import dayjs from "dayjs";
import AnimateHeight from "react-animate-height";

function Dashboard() {
	const [positions] = useGlobal("positions");
	const [brokerOrders] = useGlobal("brokerOrders");
	const [updatePositions, setUpdatePositions] = useGlobal("updatePositions");
	//const { 1: setUpdateBrokerOrders } = useGlobal("updateBrokerOrders");
	const [orders] = useGlobal("orders");
	const [updateTactics, setUpdateTactics] = useGlobal("updateTactics");
	const [updateOrders, setUpdateOrders] = useGlobal("updateOrders");
	const [showDetails, setShowDetails] = useState(false);
	const [currentItem, setCurrentItem] = useState({});

	const handleShowDetails = function (item) {
		setShowDetails(true);
		setCurrentItem(item);
		setUpdateTactics(!updateTactics);
		setUpdateOrders(!updateOrders);
	};
	const closeDetails = function (e) {
		e.preventDefault();
		setShowDetails(false);
	};

	const handleUpdatePositions = function () {
		setUpdatePositions(!updatePositions);
		console.log("Refresh position clicked");
		console.log("Update position is " + updatePositions);
	};

	// console.log(positions);
	// console.log(orders);

	return (
		<Fragment>
			<div className="flex flex-col">
				<h2 className="mb-4 text-2xl">
					<span className="mr-1 inline-block">Active Positions </span>
					<span
						title="refresh"
						onClick={handleUpdatePositions}
						className="text-xs hover:text-green-600 p-1 text-gray-600 cursor-pointer inline-block "
					>
						<FaSync />
					</span>
				</h2>
				<div className="-my-2 py-2  overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
					<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
						<table className="min-w-full ">
							<thead>
								<tr>
									<th className="sticky px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Broker
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Asset
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Price
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Entry
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Size
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Cost
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Direction
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Lev
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										P&amp;L
									</th>
									<th className=" px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Actions
									</th>
								</tr>
							</thead>
							<tbody className="bg-white">
								{positions.map((position, index) => {
									return (
										<Fragment key={index + 1}>
											<tr
												onClick={() => {
													handleShowDetails(position);
												}}
												className="cursor-pointer hover:bg-gray-100"
											>
												<td className="sticky mx-6 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<span className="bg-blue-100 text-blue-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
														{position.brokerUI}
													</span>
												</td>
												<td className=" px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 font-medium text-gray-900">
														{position.asset}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 text-gray-900">
														{position.price}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 text-gray-900">
														{position.entry}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
													{position.size}
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 text-gray-900">
														{position.cost}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<span
														className={
															(position.direction === "long"
																? "bg-green-100 text-green-800"
																: "bg-red-100 text-red-800") +
															" px-2 inline-flex text-xs leading-5 font-semibold rounded-full "
														}
													>
														{position.direction}
													</span>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 text-gray-900">
														{isNumber(position.leverage)
															? Math.round(position.leverage)
															: "None"}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 text-gray-900">
														{position.pnl} (
														{Math.round((position.pnl / Math.abs(position.cost)) * 100)}
														%)
													</div>
												</td>
												<td className="ml-12 px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
													<span className="inline-block mr-2">
														<FaCaretDown />
													</span>
													<button className="text-indigo-600 hover:text-indigo-900">
														Edit
													</button>
												</td>
											</tr>
										</Fragment>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div className="flex flex-col mt-4">
				<h2 className="mb-4 text-2xl">
					Pending Broker Orders
					<span
						title="refresh"
						className="text-xs hover:text-green-600 p-1 text-gray-600 cursor-pointer inline-block "
					>
						<FaSync />
					</span>
				</h2>
				<div className="-my-2 py-2  overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
					<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
						<table className="min-w-full ">
							<thead>
								<tr>
									<th className="sticky px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Broker
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Asset
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Action
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Intent
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Type
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Price
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Size
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										TIF
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Trigger
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Status
									</th>
									<th className=" px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Created
									</th>
								</tr>
							</thead>
							<tbody className="bg-white">
								{brokerOrders.map((order, index) => {
									return (
										<Fragment key={index + 1}>
											<tr
												onClick={handleShowDetails}
												data-details={order}
												className="cursor-pointer hover:bg-gray-100"
											>
												<td className="sticky mx-6 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<span className="bg-blue-100 text-blue-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
														{order.broker}
													</span>
												</td>
												<td className=" px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 font-medium text-gray-900">
														{order.asset}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 text-gray-900">
														{order.action}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<span
														className={
															(order.intent === "long"
																? "bg-green-100 text-green-800"
																: "bg-red-100 text-red-800") +
															" px-2 inline-flex text-xs leading-5 font-semibold rounded-full "
														}
													>
														{order.intent}
													</span>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
													{order.type}
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 text-gray-900">
														{order.price}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													{order.abSize ? order.abSize : order.pctSize}
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													{order.duration}
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													{order.triggerId}
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-xs">{order.status}</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-xs leading-5 text-gray-900">
														{dayjs(order.createdAt).format("h:mm A, M/D")}
													</div>
												</td>
												<td className="ml-12 px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
													<span className="inline-block mr-2">
														<FaCaretDown />
													</span>
													<button className="text-indigo-600 hover:text-indigo-900">
														Edit
													</button>
												</td>
											</tr>
										</Fragment>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="flex flex-col mt-4">
				<h2 className="mb-4 text-2xl">
					Pending App Orders
					<span
						title="refresh"
						className="text-xs hover:text-green-600 p-1 text-gray-600 cursor-pointer inline-block "
					>
						<FaSync />
					</span>
				</h2>
				<div className="-my-2 py-2  overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
					<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
						<table className="min-w-full ">
							<thead>
								<tr>
									<th className="sticky px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Broker
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Asset
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Action
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Intent
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Type
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Price
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Size
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										TIF
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Trigger
									</th>
									<th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Status
									</th>
									<th className=" px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Created
									</th>
								</tr>
							</thead>
							<tbody className="bg-white">
								{orders.map((order, index) => {
									return (
										<Fragment key={index + 1}>
											<tr
												onClick={handleShowDetails}
												data-details={order}
												className="cursor-pointer hover:bg-gray-100"
											>
												<td className="sticky mx-6 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<span className="bg-blue-100 text-blue-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
														{order.broker}
													</span>
												</td>
												<td className=" px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 font-medium text-gray-900">
														{order.asset}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 text-gray-900">
														{order.action}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<span
														className={
															(order.intent === "long"
																? "bg-green-100 text-green-800"
																: "bg-red-100 text-red-800") +
															" px-2 inline-flex text-xs leading-5 font-semibold rounded-full "
														}
													>
														{order.intent}
													</span>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
													{order.type}
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-sm leading-5 text-gray-900">
														{order.price}
													</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													{order.abSize ? order.abSize : order.pctSize}
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													{order.duration}
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													{order.triggerId}
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-xs">{order.status}</div>
												</td>
												<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
													<div className="text-xs leading-5 text-gray-900">
														{dayjs(order.createdAt).format("h:mm A, M/D")}
													</div>
												</td>
												<td className="ml-12 px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
													<span className="inline-block mr-2">
														<FaCaretDown />
													</span>
													<button className="text-indigo-600 hover:text-indigo-900">
														Edit
													</button>
												</td>
											</tr>
										</Fragment>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<div className="mt-8"></div>
			</div>
			<AnimateHeight
				duration={100}
				height={showDetails ? "auto" : 0}
				style={{ flexShrink: 0 }}
			>
				<div className=" inset-x-0 bottom-0 fixed mx-40">
					<span className="text-blue-800">
						<FaGripLines
							className=" cursor-pointer p-1 pl-10 bg-gray-200 "
							onClick={closeDetails}
						/>
					</span>

					<div className="">
						<Details item={currentItem} />
					</div>
				</div>
			</AnimateHeight>
		</Fragment>
	);
}

export default Dashboard;

import React, { useState, Fragment } from "react";
import { useEffect, useGlobal } from "reactn";
import Modal from "components/common/modal";

import Tactic from "./tactic";
import Axios from "axios";
import { toast } from "react-toastify";
import validateAsset from "../../helpers/validate-asset";
import PositionSelect from "./position-select";

const PositionTactics = function () {
	const [posTactics, setPosTactics] = useGlobal("appliedTactics");
	const [assets] = useGlobal("assets");
	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");
	const [selectedAssetClass, setSelectedAssetClass] = useGlobal(
		"selectedAssetClass"
	);
	const [selectedBroker, setSelectedBroker] = useGlobal("selectedBroker");
	const [modalToggle, setModalToggle] = useState(false);
	const [committedTactics, setCommittedTactics] = useState([]);
	const [pendingTactics, setPendingTactics] = useState([]);
	const [updateTactics, setUpdateTactics] = useGlobal("updateTactics");
	const [updatePositions, setUpdatePositions] = useGlobal("updatePositions");

	const handleAsset = function (e) {
		setSelectedAsset(e.target.value);
	};

	const resetPosTactics = function (e) {
		e.preventDefault();
		setPosTactics([]);
	};

	const createRules = function (e) {
		e.preventDefault();
		let vAsset = validateAsset(selectedAsset, assets, selectedBroker);
		if (!vAsset) {
			return false;
		}
		console.log(posTactics);
		let requests = [];
		posTactics.map((tactic) => {
			let rule = {};
			rule.title = tactic.title;
			rule.asset = vAsset;
			rule.tacticId = tactic.id;
			rule.trigger = {
				type: tactic.trigtemp.type,
				title: tactic.trigtemp.title,
				payload: tactic.trigtemp.payload,
				asset: tactic.trigtemp.asset,
				status: "active",
				trigplateId: tactic.trigtempId,
			};
			rule.trigger.id = 0;
			rule.trigger.status = "active";
			rule.trigger.trigplateId = tactic.trigtempId;
			rule.orders = [];
			tactic.tactic_ordtemps.map((ordtemp) => {
				let order = {
					broker: selectedBroker,
					asset: vAsset,
					action: ordtemp.action,
					intent: ordtemp.intent,
					type: ordtemp.type,
					duration: ordtemp.duration,
					price: ordtemp.price,
					abSize: ordtemp.action === "entry" ? ordtemp.size : null,
					pctSize: ordtemp.action === "exit" ? ordtemp.size : null,
					status: tactic.trigtemp.type === "now" ? "pending" : "inactive",
					statusDetails: "order added",
					ordplateId: ordtemp.id,
					tacplateId: tactic.id,
					trigplateId: tactic.trigtempId,
					assetClass: selectedAssetClass,
				};

				rule.orders.push(order);
			});
			console.log(rule);
			requests.push(Axios.post(process.env.REACT_APP_BACKEND + "rules", rule));
		});
		Axios.all(requests)
			.then(
				Axios.spread((...responses) => {
					console.log(responses);
					toast.success(
						"Tactics have been committed for this position, and relevant orders have been created"
					);

					setCommittedTactics(responses);
					setModalToggle(true);
				})
			)
			.catch((errors) => {
				console.log(errors);
			});

		let timer = setTimeout(() => setUpdatePositions(!updatePositions), 1300);
	};

	useEffect(() => {
		const fetchPendingTactics = async function () {
			Axios.get(process.env.REACT_APP_BACKEND + "rules?asset=" + selectedAsset)
				.then((response) => {
					setPendingTactics(response.data);
					console.log(response.data);
				})
				.catch((errata) =>
					console.log(
						"Could not fetch pending tactics because " + errata.error.message
					)
				);
		};
		fetchPendingTactics();
	}, [updateTactics]);

	return (
		<Fragment>
			{/* <span
        className="text-xs text-blue-700 cursor-pointer"
        onClick={() => setModalToggle(true)}
      >
        The selected asset has pending tactics. Click here to view
      </span> */}
			{pendingTactics.length > 0 && (
				<Fragment>
					<p className="text-xs">This position has the following pending tactics:</p>
					<ol className="list-decimal list-inside text-xs">
						{pendingTactics.map((tactic, index) => {
							return (
								<li key={index + 1}>
									<span className="inline-block">{tactic.title}</span>
									{tactic.trigger.type === "callback" && (
										<span>
											({process.env.REACT_APP_BACKEND}
											webhook/
											{tactic.trigger.id})
										</span>
									)}
									{tactic.trigger.type === "time" && (
										<span>(execution time -{tactic.trigger.payload})</span>
									)}
								</li>
							);
						})}
					</ol>
					<p className="text-xs mt-3">
						<a href="#">Click here to view order history for this position</a>
					</p>
				</Fragment>
			)}
			<p className="text-xs mt-3">
				Click the + sign on any tactic to add it to the position
			</p>
			<div className="border-dashed border-2 border-gray-500 -m-4 mt-1 flex flex-wrap p-2 pb-8 pos-tactics">
				{posTactics.map((tactic, index) => {
					return (
						<Tactic
							key={index}
							tid={tactic.id}
							posTacticsId={tactic.posTacticsId}
							title={tactic.title}
							action={tactic.tactic_ordtemps[0].action}
							intent={tactic.tactic_ordtemps[0].intent}
							quantity={tactic.tactic_ordtemps[0].size}
							ordTempTitle={tactic.tactic_ordtemps[0].title}
							trigger={tactic.trigtemp}
							context="posSelect"
						/>
					);
				})}
			</div>
			<div className="p-5 mt-4 flex justify-between">
				<button
					onClick={resetPosTactics}
					className="rounded-sm antialiased border border-yellow-500 tracking-widest hover:shadow hover:border-yellow-400 text-xs font-semibold uppercase p-1 px-4 ml-3"
				>
					Clear
				</button>
				<button
					onClick={createRules}
					className="bg-yellow-500 p-3 rounded-sm antialiased border border-yellow-500 tracking-widest hover:shadow hover:bg-yellow-400 hover:border-yellow-400 text-xs font-semibold uppercase p-1 px-4 ml-3"
				>
					Commit Tactics
				</button>
			</div>
			{modalToggle && (
				<Modal>
					<div className="font-bold">
						Tactics Committed for New Position on {selectedAsset}
					</div>
					<p className="text-xs">
						Copy the webhook URLs and paste in the relevant application (such as
						TradingView)
					</p>
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 sm:gap-2 md:gap-4 bg-orange-100 pb-4 mt-2">
						{committedTactics.map((response, index) => {
							let rule = response.data;
							return (
								<Tactic
									key={index}
									tid={rule.id}
									title={rule.title}
									action={rule.orders[0].action}
									intent={rule.orders[0].intent}
									quantity={rule.orders[0].abSize || rule.orders[0].pctSize}
									ordTempTitle={rule.orders[0].title}
									trigger={rule.trigger}
									context="tacticsCommitted"
								/>
							);
						})}
					</div>

					<div className="flex justify-between">
						<div>
							<p className="text-xs"> Continue with another position: </p>
							<button
								onClick={() => setModalToggle(false)}
								className="bg-teal-500 inline-flex items-center rounded-sm antialiased border border-teal-500 tracking-widest hover:shadow hover:bg-teal-400 hover:border-teal-400 text-xs font-bold uppercase p-1 px-4 mr-4"
							>
								Start with Same Tactics
							</button>
							<button
								onClick={() => {
									setPosTactics([]);
									setModalToggle(false);
								}}
								className="bg-teal-500 inline-flex items-center rounded-sm antialiased border border-teal-500 tracking-widest hover:shadow hover:bg-teal-400 hover:border-teal-400 text-xs font-bold uppercase p-1 px-4"
							>
								Apply New Tactics
							</button>
						</div>
						<div>
							<button> Go to Dashboard</button>
						</div>
					</div>
				</Modal>
			)}
		</Fragment>
	);
};

export default PositionTactics;

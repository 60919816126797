import React, { useState, Fragment } from "react";
import { useGlobal } from "reactn";
import Brokers from "components/common/brokers";
import Assets from "components/common/assets";
import Axios from "axios";
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import Modal from "components/common/modal";

const PositionSelect = function () {
	const [posSelect, setPosSelect] = useState("newpos");
	const [positions, setPositions] = useGlobal("positions");
	const [selectedAsset, setSelectedAsset] = useGlobal("selectedAsset");
	const [selectedBroker, setSelectedBroker] = useGlobal("selectedBroker");
	const [updateTactics, setUpdateTactics] = useGlobal("updateTactics");
	const handlePosSelect = function (e) {
		setPosSelect(e.target.value);
		if (e.target.value !== "newpos") {
			setSelectedAsset(e.target.value);
			setSelectedBroker(
				e.target.options[e.target.selectedIndex].dataset.broker
			);
			setUpdateTactics(!updateTactics);
		}
		//toast.success("Selected Asset: "+e.target.value);
	};

	return (
		<div className="mt-4 p-2">
			<select
				value={posSelect}
				onChange={handlePosSelect}
				className="form-select text-xs h-10 py-1 w-full mb-4"
			>
				<option value="newpos">New Position</option>
				{positions.map((position, index) => {
					return (
						<option
							key={position.id}
							value={position.asset}
							data-broker={position.broker}
						>
							{position.asset} {position.direction} (
							{position.size}) - {position.brokerUI}
						</option>
					);
				})}
				<option
					className="text-green-500"
					value="AAPL"
					data-broker="robinhood"
				>
					AAPL Long (120 units) - Robinhood
				</option>
				<option>ADABTCJ50 (100 units) - Bitmex </option>
				<option>MU Calls May 30 2020 (4 units) - Tradier</option>
			</select>
			{posSelect == "newpos" ? (
				<div className="flex -mx-1">
					<Brokers styling="form-select text-xs h-10 py-1 mx-1 my-1 w-1/2" />
					<Assets
						styling="auto-complete-input form-input w-full m-1 h-10 text-xs"
						chain="normal"
					/>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default PositionSelect;
